import { Component, inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { IamService } from '@services/iam.service'
import { Organisation } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { NavigationService } from '../navigation/navigation.service'

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  proficloud = inject(ProficloudService)
  iam = inject(IamService)
  navigation = inject(NavigationService)
  router = inject(Router)

  filteredOrganizations: Organisation[] = []

  ngOnInit(): void {
    this.filteredOrganizations = this.proficloud.organisations
  }

  async openSettings(tabName: string) {
    await this.router.navigate(['/services/account/user-settings'], { queryParams: { tab: tabName } })
  }

  searchExecuted($event: string) {
    if ($event) {
      this.filteredOrganizations = this.proficloud.organisations.filter((org) => org.organizationName.toLowerCase().includes($event.toLowerCase()))
    } else {
      this.filteredOrganizations = this.proficloud.organisations
    }
  }
}
