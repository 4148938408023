import { Pipe, PipeTransform } from '@angular/core'
import { AuthorisationService } from '@services/authorisation.service'
import { ProficloudService } from '@services/proficloud.service'
import { AppService } from 'src/app/app.service'
import { NavItem } from '../components/navigation/navigation.model'

@Pipe({
  name: 'navItemVisible',
  pure: false,
  // Note: This has to be pure because their is a race condition on loading organisations and also the current org can change
  // I wanted to do something fancy with an observable but that doesn't seem to work well in pipes.
})
export class NavItemVisiblePipe implements PipeTransform {
  constructor(private auth: AuthorisationService, private proficloud: ProficloudService, private app: AppService) {}

  transform(navItem: NavItem, ...args: unknown[]): unknown {
    if (navItem.adminPermission) {
      if (!this.proficloud.currentOrganisation) {
        return false
      } else {
        return this.auth.isAdmin(this.proficloud.currentOrganisation)
      }
    }

    if (this.app.environment.frontend !== 'production') {
      return true
    }

    if (this.app.environment.frontend === 'production') {
      // for every Navigation Item except the Admin-Service
      if (navItem.id !== 'admin-service') {
        if (this.proficloud.userHasFeatureFlag(navItem.showInProductionFlag)) {
          return true
        }
        // for the Admin-Service Navigation Item
      } else {
        if (this.proficloud.userHasAdminFeatureFlag(navItem.showInProductionFlag)) {
          return true
        }
      }
      return !navItem.hideInProduction
    }
  }
}
