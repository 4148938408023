import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { BillingService } from '@services/billing/billing.service'
import { ContentfulService } from '@services/billing/contentful-interfaces'
import { ContentService } from '@services/content/content.service'
import { ProficloudService } from '@services/proficloud.service'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { AppService } from 'src/app/app.service'

@Component({
  selector: 'app-service-list',
  template: `
    <flex-row style="padding: 40px; flex-wrap: wrap;">
      <!-- dynamic contentful services! -->
      <ng-container *ngFor="let service of content.content.services">
        <div-relative
          class="card"
          *ngIf="service.fields.productionReadiness || app.environment.frontend === 'dev' || app.environment.frontend === 'staging'"
          [routerLink]="[isPublic ? '/service/store' : '/services/store', service.fields.urlSlug]"
          style="margin-right: 20px; border-radius: 5px; margin-top: 20px;">
          <div
            style="z-index: 0; background-size: cover; position: absolute; top: 0; left: 0; width: 100%; height: 200px; border-top-left-radius: 5px; border-top-right-radius: 5px;"
            [style.background-image]="'url(' + service.fields.heroImage.fields.file.url + ')'"></div>

          <flex-row-center style="z-index: 1; position: relative;">
            <!-- icon -->
            <flex-col-center-center
              style="margin-top: 100px; z-index: 1; width: 60px; height: 60px; padding: 30px; border-radius: 100%; border: 1px solid #333; background: #efefef;">
              <img [src]="service.fields.icon.fields.file.url" />
            </flex-col-center-center>
          </flex-row-center>

          <!-- service name -->
          <flex-row-center class="semibold" style="position: relative; z-index: 1; font-size: 20px; margin: 15px 0;">
            {{ service.fields.name | translate }}
          </flex-row-center>

          <!-- price -->
          <flex-row-center style="position: relative; z-index: 1; font-size: 20px; font-weight: 300; color: #666;">{{
            service.fields.startsAt
          }}</flex-row-center>

          <!-- bullets -->
          <flex-col style="margin-top: 20px; position: relative;">
            <flex-row *ngFor="let bullet of service.fields.highlights" style="padding: 10px 0;">
              <app-icon name="check" style="min-width: 20px; min-height: 20px; width: 20px; height: 20px; margin-right: 10px;" color="#222"></app-icon>
              <div>{{ bullet }}</div>
            </flex-row>
          </flex-col>
        </div-relative>
        <!-- end of card -->
      </ng-container>
      <flex-row-center *ngIf="noServicesAvailable"> There are now services available for this organisation or user currently. </flex-row-center>
    </flex-row>
  `,
  // styleUrls: ['./service-list.component.scss']
  styles: [
    `
      .card {
        box-shadow:
          rgba(0, 0, 0, 0.12) 0px 1px 3px,
          rgba(0, 0, 0, 0.24) 0px 1px 2px;
        border-radius: 4px;
        width: 300px;
        padding: 20px;
        margin-right: 20px;
        cursor: pointer;
      }
    `,
  ],
})
export class ServiceListComponent implements OnInit, OnDestroy {
  @Input() isPublic = false

  orgListedSubscription: Subscription

  contentLoadedSubscription: Subscription

  noServicesAvailable = false

  constructor(
    public route: ActivatedRoute,
    public proficloud: ProficloudService,
    public billing: BillingService,
    public content: ContentService,
    public app: AppService,
    public translate: TranslateService
  ) {
    // this.isPublic = this.route.snapshot.data['public']
  }

  ngOnInit() {
    this.contentLoadedSubscription = this.content.servicesLoaded$.pipe(filter((loaded) => !!loaded)).subscribe(() => {
      // Remove service from services if it has access field is provided but user is not part of the access list
      if (this.proficloud.organisations && this.proficloud.organisations.length === 0) {
        this.noServicesAvailable = true
        return
      } else {
        this.noServicesAvailable = false
        this.content.content.services = this.checkPermissionsForServices()
      }
    })

    // subscribe for the organisation listed event
    this.orgListedSubscription = this.proficloud.organisationsListed$.subscribe(() => {
      if (!this.content.content.services?.length) {
        this.noServicesAvailable = true
        return
      } else {
        this.noServicesAvailable = false
        this.content.content.services = this.checkPermissionsForServices()
      }
    })
  }

  private checkPermissionsForServices(): ContentfulService[] {
    const filteredServices: ContentfulService[] = []

    this.content.content.services?.forEach((s) => {
      // If the service can only be booked by the API the user shall not see the service at all
      if (s.fields.onlyBookableByApi) {
        return
      }

      if (!s.fields.hasAccess) {
        // In this case the access is not restricted and the service should be part of the current content
        filteredServices.push(s)
      } else {
        s.fields.hasAccess.forEach((hA) => {
          // if user is part of the access list add the service because the user is allowed to selection it
          if (
            hA.fields.userId === this.proficloud.keycloakData.userDetails?.data.userId &&
            this.proficloud.currentOrganisation &&
            hA.fields.orgId === this.proficloud.currentOrganisation.organizationId
          ) {
            filteredServices.push(s)
          }
        })
      }
    })

    return filteredServices
  }

  ngOnDestroy(): void {
    this.orgListedSubscription?.unsubscribe()
    this.contentLoadedSubscription?.unsubscribe()
  }
}
