import { animate, state, style, transition, trigger } from '@angular/animations'

export const profileLogoHoverAnimation = trigger('profileLogoHover', [
  state(
    'static',
    style({
      boxShadow: '0px 3px 6px #00000029',
    })
  ),
  state(
    'hovered',
    style({
      boxShadow: '0px 5px 8px #C7C7C7FF',
    })
  ),
  transition('static <=> hovered', [animate('0.2s linear')]),
])
