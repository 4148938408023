<div class="shell">
  <!-- Topbar
  <div class="shell-topBar" [style.background-image]="'url(' + wlService.topBarBackgroundImage + ')'"> -->
  <div class="shell-topBar">
    <!-- Logo -->
    @if ((navigation.navigationToggled$ | async) === false) {
      <div class="topBarLeft">
        <app-proficloud-logo class="topBarLogo"></app-proficloud-logo>
      </div>
    }
    @if (navigation.navigationToggled$ | async) {
      <div class="topBarLeft--minimized">
        <app-themed-proficloud-logo></app-themed-proficloud-logo>
      </div>
    }
    <!-- Remaining space -->
    <div class="shell-topBar-main">
      <div class="shell-topBar-center">
        <flex-col class="topBarTitle">
          <div id="routeTitle" class="semibold shell-topBar-center-title">{{ ui.routeTitle | translate }}</div>
          <div id="breadcrumb" class="shell-topBar-center-breadcrumb">
            {{ ui.routeSubTitle | translate }} {{ ui.routeExtra ? (ui.routeExtra | translate) : '' }}
          </div>
        </flex-col>

        <!-- TODO: This got left over from something and perhaps could be moved into the regular notifications bar -->
        <!-- <div class="shell-topBar-center-notification" *ngIf="deviceService.devicesWithErrorCount > 0">
          <app-icon name="error" color="#fff" style="width: 20px; margin-right: 15px"></app-icon>
          {{ deviceService.devicesWithErrorCount }} of your devices require{{ deviceService.devicesWithErrorCount > 3 ? 's' : '' }}
          immediate attention.
        </div> -->
      </div>
      <!-- Right -->
      <div-grow class="shell-topBar-right">
        <app-notification-list></app-notification-list>
        <app-notification-bell></app-notification-bell>

        <a target="_blank" href="https://proficloud.io/knowledge-hub/faqs/" style="text-decoration: none">
          <flex-col-center-center
            style="
              margin-right: 16px;
              background: var(--jet-black);
              color: white;
              font-size: 20px;
              height: 24px;
              width: 24px;
              border-radius: 100%;
              cursor: pointer;
            "
            >?
          </flex-col-center-center>
        </a>
        <div (clickOutside)="navigation.hideOrgMenu()">
          <div class="shell__organization-name" style="padding: 0 16px 0 0; cursor: pointer" [id]="'active-organization'" (click)="navigation.toggleOrgMenu()">
            <h4 style="white-space: nowrap; display: flex; align-items: center">
              {{ proficloud.currentOrganisation?.organizationName }}<app-icon name="{{ 'chevronDown' }}" style="width: 20px" color="#343434"></app-icon>
            </h4>
          </div>
          @if (navigation.organisationMenuToggled$ | async) {
            <app-profile-menu class="shell-topBar-right-menu" style="margin-right: 15px; top: 101%"></app-profile-menu>
          }
        </div>

        <div-relative (clickOutside)="navigation.hideProfileMenu()">
          <div
            id="userMenu"
            class="shell-topBar-right-userImage"
            (mouseenter)="profileLogoHovered = true"
            (mouseleave)="profileLogoHovered = false"
            [@profileLogoHover]="profileLogoHovered ? 'hovered' : 'static'"
            [ngStyle]="{
              background: 'linear-gradient(0deg, ' + firstNameColor.color + ' 0%, ' + lastNameColor.color + ' 100%)'
            }"
            (click)="navigation.toggleProfileMenu()"
            [id]="'proficloud-user-icon'">
            {{ firstNameColor.id }}{{ lastNameColor.id }}
          </div>
          @if (navigation.profileMenuToggled$ | async) {
            <app-profile-menu class="shell-topBar-right-menu"></app-profile-menu>
          }
        </div-relative>
      </div-grow>
    </div>
  </div>
  <!-- bottom (slide bar and content -->
  <div id="content" #content>
    <!-- side bar navigation (dup with above) -->
    <app-navigation></app-navigation>

    <!-- right content: router outlet -->
    <div id="outlet">
      <!-- router outlet -->
      <router-outlet></router-outlet>
    </div>
  </div>

  @if (navigation.createOrganisationVisible$ | async) {
    <pc-overlay [id]="'create-new-organization-02-pop-up'" [config]="createOrgDialog" (closeClick)="navigation.hideCreateOrganisation()">
      <ng-container body>
        <div style="width: 100%; margin-top: 20px">
          <form [formGroup]="createOrganisationForm">
            <div style="width: 100%; margin-bottom: 10px">
              <pc-input-textfield
                id="create-new-organization-02-organization-name-input"
                [control]="createOrgNameControl"
                placeholder="Organization Name"
                variant="fit-width"
                hint="Allowed characters are (a-z A-Z 0-9 - _ . @)"></pc-input-textfield>
            </div>
          </form>
          <div style="font-size: 16px; font-style: normal; font-weight: 400; line-height: 20px">
            {{ 'USER_MANAGEMENT_SERVICE.REQUIRED_FIELD_1' | translate }} <span style="color: red">*</span>
            {{ 'USER_MANAGEMENT_SERVICE.REQUIRED_FIELD_2' | translate }}
          </div>
        </div>
      </ng-container>
      <flex-row-end buttons style="justify-content: flex-end">
        <pc-button
          id="create-new-organization-02-create-organization"
          size="small"
          [disabled]="!createOrganisationForm.valid"
          [text]="'SHARED.CREATE' | translate"
          (btnClick)="createOrganization(); navigation.createOrganisationVisible$.next(false)"></pc-button>
      </flex-row-end>
    </pc-overlay>
  }
</div>

<!-- NEW MODAL -->
@if (billing.showAssignDevicesModal$ | async; as config) {
  <app-subscription-devices-modal [config]="config"> </app-subscription-devices-modal>
}

@if (billing.showAssignMetricsModal$ | async; as config) {
  <app-subscription-metrics-modal [singleDevice]="config"> </app-subscription-metrics-modal>
}

@if (billing.showEmmaAssignDevicesModal$ | async; as config) {
  <app-emma-assign-devices-modal [config]="config"> </app-emma-assign-devices-modal>
}

@if (billing.showSelectSubscriptionModal$ | async; as config) {
  <app-select-subscription-modal [config]="config"></app-select-subscription-modal>
}
