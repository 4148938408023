import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ProficloudService } from '@services/proficloud.service'
import { Observable, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { AppService } from 'src/app/app.service'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { DeviceService } from '../../../modules/device-management/services/device.service'
import { AuthorisationService } from '../../../services/authorisation.service'
import { BillingService } from '../../../services/billing/billing.service'
import { ContentfulPlanVariant, ContentfulService, ServiceMedia } from '../../../services/billing/contentful-interfaces'
import { ContentService } from '../../../services/content/content.service'
import { DeviceTypeInformation, ServiceIds } from '../../../services/proficloud.interfaces'

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss', '../../../../shared/styles/shared-styles.scss'],
  //   template: `
  // <!-- details page container -->

  // `,
})
export class ServiceDetailComponent implements OnInit, OnDestroy {
  loadingStates: { [key: string]: boolean } = {}

  constructor(
    // angular
    private route: ActivatedRoute,
    public cd: ChangeDetectorRef,
    // proficloud
    private deviceService: DeviceService,
    public proficloud: ProficloudService,
    public billing: BillingService,
    public content: ContentService,
    public app: AppService,
    public translate: TranslateService,
    public authorization: AuthorisationService,
    private statusOverlay: PcStatusOverlayService
  ) {
    ;(window as unknown as any).serviceCmp = this
  }

  service?: ContentfulService

  urlSlug: string

  availableServicePlans: ContentfulPlanVariant[] = []

  availableDevices?: DeviceTypeInformation[]

  userSelectedMedia: ServiceMedia

  userSelectedMediaType: 'image' | 'video'

  orgSwitchedSubscription: Subscription

  showChangelog = false

  selectUserMedium(medium: ServiceMedia) {
    this.userSelectedMedia = medium
    this.userSelectedMediaType = medium.fields.file.contentType.includes('video') ? 'video' : 'image'
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.urlSlug = params.id

      this.content.servicesLoaded$.pipe(filter((loaded) => !!loaded)).subscribe({
        next: () => {
          // If the organization data is available initialize. If not wait for it, this scenario can happen if the user refreshes the service details manually (f5)
          if (this.proficloud.currentOrganisation) {
            this.initialize()
          } else {
            this.proficloud.organisationsListed$.subscribe({
              next: () => this.initialize(),
            })
          }
        },
      })

      // sets all loading animations to false
      this.billing.previewIsLoading$.subscribe((loading) => {
        if (!loading) {
          for (let key in this.loadingStates) {
            this.loadingStates[key] = false
          }
        }
      })

      this.orgSwitchedSubscription = this.proficloud.organisationSwitched$.subscribe((res) => {
        this.statusOverlay.resetStatus()
        this.initialize()
      })
    })
  }

  ngOnDestroy(): void {
    this.orgSwitchedSubscription.unsubscribe()
  }

  private initialize() {
    this.service = this.content.content.services?.find((service) => service.fields.urlSlug === this.urlSlug)
    if (!this.service) {
      console.warn('Service not found')
      return
    }
    // this.availableServicePlans = this.service.fields.planVariants

    // This filters available packages of the service based on permission fields/flags provided by contentful
    this.getAccessiblePlans$(this.service).subscribe({
      next: (availablePlans) => {
        this.availableServicePlans = availablePlans
      },
      error: () => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.servicePackageNoPermissionError)
      },
    })

    this.selectUserMedium(this.service.fields.serviceMedia[0])
    // Excemption for DMS Basic Add on (same devices as dms)
    let serviceId: ServiceIds | undefined = this.service.fields.id
    if (serviceId === 'dmsAddonBasic') {
      serviceId = this.content.content.services?.find((service) => service.fields.urlSlug === 'device-management-service')?.fields.id
    }

    if (!serviceId) {
      console.warn('Service ID not found')
      return
    }
    this.availableDevices = this.deviceService.serviceAvailableDevices(serviceId)
  }

  onAddServiceClick(options: { service: ContentfulService; plan: ContentfulPlanVariant; quantity: number }) {
    // if the user is not authenticated, they need to login first
    if (!this.proficloud.keycloakData.session) {
      this.billing.showAuthRequired$.next(true)
      return
    }

    // if this is a paid tsd plan but the user already has a subscription
    if (
      this.billing.tsdSubscription &&
      this.billing.tsdSubscription.bookedPackageId !== 'free' &&
      options.plan.fields.price > 0 &&
      options.service.fields.id === 'tsd'
    ) {
      // check if this is a downgrade (not allowed)
      const p = this.billing.getTsdPackageById(this.billing.tsdSubscription.bookedPackageId)
      if (options.plan.fields.price < (p?.pricing.recurringFee || 0)) {
        this.billing.showContactRequired$.next(true)
        return
      }

      // trigger animation for the selected card
      this.loadingStates[options.plan.fields.sapNumber] = true
      // this is an upgrade, get and show preview
      this.billing.upgradeSubscriptionPreview(options.service, this.billing.tsdSubscription.id, {
        quantity: 1,
        packageId: options.plan.fields.sapNumber,
      })

      return
    }

    // if the plan is free, send the user there
    if (options.plan.fields.price === 0) {
      const serviceUrl = options.service.fields.redirection[this.app.environment.backend]
      window.location.href = serviceUrl
      return
    }

    this.billing.addSubscriptionToCart(options)

    if (!this.billing.billingAccount) {
      // if the user has NO billing account -> start billing account promt
      this.billing.showBillingAddressRequired$.next(true)
    } else {
      // if the user already HAS a billing account -> go straight to checkout
      this.billing.showCheckoutForm$.next(true)
    }
  }

  // todo: pull out packages instead
  getServiceSubscriptions(serviceName: 'Time Series Data Service' | 'ImpulseAnalytics Service' | any) {
    if (serviceName === 'Time Series Data Service') {
      if (this.billing.tsdSubscription) {
        return [this.billing.tsdSubscription]
      } else {
        return []
      }
    }
    if (serviceName === 'ImpulseAnalytics Service') {
      return this.billing.impulseAnalyticsSubscriptions || []
    }
  }

  // Returns a list of packages (plans) the user has access to.
  getAccessiblePlans$(service: ContentfulService): Observable<ContentfulPlanVariant[]> {
    const accessiblePlans: ContentfulPlanVariant[] = []

    return new Observable((sub) => {
      service.fields.planVariants.forEach((planVariant) => {
        // If only bookable by API this package shall not be visible to the user
        if (planVariant.fields.onlyBookableByApi) {
          return
        }
        // only do this for plans having the hasAccess field at all. If not add the package
        if (planVariant.fields.hasAccess) {
          planVariant.fields.hasAccess.forEach((hasAccess) => {
            if (
              hasAccess.fields.userId === this.proficloud.keycloakData.userDetails?.data.userId &&
              hasAccess.fields.orgId === this.proficloud.currentOrganisation.organizationId
            ) {
              accessiblePlans.push(planVariant)
            }
          })
        } else {
          accessiblePlans.push(planVariant)
        }
      })

      if (accessiblePlans.length > 0) {
        sub.next(accessiblePlans)
      } else {
        sub.error()
      }
    })
  }
}
