@if (notificationService.notDismissedNotifications.length > 0) {
  <div
    class="notification-list__container"
    id="summary"
    #notificationListContainer
    (window:resize)="onResize()"
    (click)="toggleNotificationPanel(); $event.stopPropagation()"
    (clickOutside)="notificationPanelVisible = false"
    cdkOverlayOrigin
    #notificationPanelTrigger="cdkOverlayOrigin">
    @if (notificationService.notDismissedNotifications[0]) {
      <app-notification-list-item
        class="notification-list__item-1"
        [notification]="notificationService.notDismissedNotifications[0]"></app-notification-list-item>
    }
    @if (notificationService.notDismissedNotifications[1] && !notificationPanelVisible) {
      <app-notification-list-item
        class="notification-list__item-2"
        [notification]="notificationService.notDismissedNotifications[1]"></app-notification-list-item>
    }
    @if (notificationService.notDismissedNotifications[2] && !notificationPanelVisible) {
      <app-notification-list-item
        class="notification-list__item-3"
        [notification]="notificationService.notDismissedNotifications[2]"></app-notification-list-item>
    }
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="notificationPanelTrigger"
      [cdkConnectedOverlayOpen]="notificationPanelVisible"
      [cdkConnectedOverlayPositions]="positionPairs">
      <div class="notification-list__list-container" [style]="'width: ' + notificationPanelWidth + 'px'">
        <pc-scroll>
          @if (notificationPanelVisible) {
            <div class="notification-list__list-container__items" id="list" [style]="'width: ' + notificationPanelWidth + 'px'">
              <div class="notification-list__list" (clickOutside)="notificationPanelVisible = false">
                @for (notification of notificationService.notDismissedNotifications | slice: 1 : 50; track notification) {
                  @if (
                    notification.serviceNotification.responsible !== 'emma' ||
                    (notificationService.isEmmaAlertMessage(notification.serviceNotification) && !notification.serviceNotification.acknowledged)
                  ) {
                    <app-notification-list-item style="margin: 0 8px 0 8px" [notification]="notification"></app-notification-list-item>
                  }
                }
              </div>
            </div>
          }
        </pc-scroll>
        @if (notificationService.notDismissedNotifications.length > 1) {
          <flex-row-end style="width: 100%">
            <div style="margin-right: 8px; margin-top: 8px">
              <pc-button
                [text]="'SHARED.DISMISS_NOTIFICATIONS' | translate"
                [wide]="true"
                (btnClick)="notificationService.dismissAllNotifications(); notificationPanelVisible = false"></pc-button>
            </div>
          </flex-row-end>
        }
      </div>
    </ng-template>
  </div>
}

@if (showDeleteConfirmation) {
  <pc-overlay [config]="{ title: 'Confirm dismiss' }" (closeClick)="showDeleteConfirmation = false">
    <!-- BODY -->
    <div body>
      <div>{{ 'CONFIRM_NOTIFICATION_DELETE' | translate }}</div>
    </div>
    <!-- BUTTONS -->
    <flex-row-between buttons>
      <pc-button [text]="'SHARED.CANCEL' | translate" (btnClick)="showDeleteConfirmation = false"></pc-button>
      <pc-button [text]="'SHARED.DISMISS' | translate" (btnClick)="currentNotification.dismissed = true; showDeleteConfirmation = false"></pc-button>
    </flex-row-between>
  </pc-overlay>
}
