import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { BillingService } from '@services/billing/billing.service'
import { ShoppingCart } from '@services/proficloud.interfaces'
import { addYears, format } from 'date-fns'

@Component({
  selector: 'app-cart-calculation',
  templateUrl: './cart-calculation.component.html',
  styleUrls: ['./cart-calculation.component.scss'],
})
export class CartCalculationComponent {
  @Input() shoppingCart: ShoppingCart

  @Input() tsdUpgrade = false

  start = format(new Date(), 'dd.MM.yyyy')

  end = format(addYears(new Date(), 2), 'dd.MM.yyyy')

  constructor(
    public router: Router,
    public billing: BillingService,
    public translate: TranslateService
  ) {}

  public isEmma() {
    return this.router.url.indexOf('energy-management-service') >= 0
  }
}
