import { ConnectionPositionPair } from '@angular/cdk/overlay'
import { Component, Input, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'
import { NavItem } from '../navigation/navigation.model'
import { NavigationService } from '../navigation/navigation.service'
import { minimizingNavItemAnimation } from './navigation-item.animations'

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss', '../../../shared/styles/shared-styles.scss'],
  animations: [minimizingNavItemAnimation],
})
export class NavigationItemComponent {
  @Input() navItem: NavItem

  @Input() minimized: boolean = false

  window = window

  @ViewChild('childPanelTrigger') childPanelTrigger: any

  childrenVisible = false

  positionPairs: ConnectionPositionPair[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ]

  constructor(
    public proficloud: ProficloudService,
    public ui: UiService,
    public app: AppService,
    public translate: TranslateService,
    private router: Router,
    public navigation: NavigationService
  ) {
    // subscribe to navigation changes in order to toggle the children panel
    this.navigation.selectedNavigationItem$.subscribe({
      next: (navigationItem) => {
        if (navigationItem && navigationItem === this.navItem.showChildrenAt && !this.childrenVisible) {
          this.childrenVisible = true
        } else {
          this.childrenVisible = false
        }
      },
    })
  }

  openRoute() {
    if (this.navItem.showChildrenAt) {
      this.navigation.selectNavigationItem(this.navItem.showChildrenAt)
    }

    // Only opens the submenu if navigation is minimized and nav item has children
    if (this.minimized && this.navItem.children) {
      return
    }

    if (this.navItem.routerLink) {
      this.router.navigate([this.navItem.routerLink])
    } else {
      this.window.open(this.navItem.link, '_blank')
    }
  }
}
