<div style="padding: 0px; display: flex; flex-direction: column; flex-grow: 1; box-sizing: border-box">
  <div style="background: none; flex-grow: 1; overflow-y: scroll; height: 1px">
    <router-outlet></router-outlet>
  </div>

  <!-- add billing dialog -->
  <pc-overlay *ngIf="billing.showBillingAddressRequired$ | async" [config]="requiredDialog" (closeClick)="billing.showBillingAddressRequired$.next(false)">
    <ng-container body>
      <div style="font-size: 18px">{{ 'STORE.CONFIRM_BILLING_INFORMATION' | translate }}</div>
    </ng-container>

    <flex-row-end buttons style="justify-content: flex-end">
      <pc-button id="edit-save" [text]="'STORE.ENTER_BILLING_INFORMATION' | translate" (btnClick)="onContinueClick()"></pc-button>
    </flex-row-end>
  </pc-overlay>

  <!-- billing form -->

  <app-billing-address-overlay
    *ngIf="billing.showBillingAddressForm$ | async"
    (closeClick)="billing.showBillingAddressForm$.next(false)"
    (submitClick)="onBillingAccountFormSubmit()"></app-billing-address-overlay>

  <!-- checkout (todo) -->
  <pc-overlay *ngIf="billing.showCheckoutForm$ | async" [config]="checkoutDialog" (closeClick)="billing.showCheckoutForm$.next(false)">
    <ng-container body>
      <app-cart-calculation [shoppingCart]="billing.billingDetails.shoppingCart" style="display: block; width: 100%"></app-cart-calculation>

      <div style="width: 100%">
        <!-- middle: free text reference -->
        <pc-input-textfield
          [control]="billing.orderReferenceControl"
          placeholder="Your reference (optional)"
          size="small"
          variant="fit-width"
          (valueChanged)="billing.referenceInput = $event"></pc-input-textfield>

        <flex-row-between>
          <flex-col style="width: 45%">
            <div>
              <h4>{{ 'STORE.BILLING_ADDRESS' | translate }}</h4>
              <div>{{ billing.billingAccount?.companyName || 'no company name given' }}</div>
              <div>{{ billing.billingAccount?.firstName }} {{ billing.billingAccount?.lastName }}</div>
              <div>{{ billing.billingAccount?.address.street }} {{ billing.billingAccount?.address.houseNumber }}</div>
              <div>{{ billing.billingAccount?.address.postalCode }} {{ billing.billingAccount?.address.city }}</div>
              <div>{{ billing.countryMapping[billing.currentCountry] }}</div>
            </div>
          </flex-col>

          <flex-col style="width: 45%">
            <div *ngIf="billing.getContractPartner() as contractPartner">
              <h4>{{ 'STORE.CONTRACT_PARTNER' | translate }}</h4>
              <div>{{ contractPartner.companyName }}</div>
              <div style="white-space: pre">{{ contractPartner.companyAddress }}</div>
            </div>
          </flex-col>
        </flex-row-between>

        <!-- bottom: billing address -->

        <h4>{{ 'STORE.CONFIRMATION_EMAIL' | translate }}</h4>
        <!-- t&c -->
        <flex-row>
          <!-- left: checkbox -->
          <div>
            <input type="checkbox" id="termsCheckbox" id="termsCheck" #termsCheck (change)="termsAccepted = $event.target.checked" />
          </div>
          <!-- right: wording -->
          <div>
            <label
              for="termsCheckbox"
              style="cursor: default"
              [innerHtml]="
                'STORE.TERMS_AND_CONDITIONS' | translate: { param1: billing.links.termsAndConditions, param2: billing.links.softwareLicenseTerms }
              "></label>
          </div>
        </flex-row>
      </div>
      <!-- full width content -->
    </ng-container>

    <flex-row-end buttons style="justify-content: flex-end">
      <pc-button
        id="purchase-license"
        [disabled]="!termsCheck.checked"
        [text]="'STORE.ORDER_LICENSE' | translate"
        (btnClick)="submitCheckoutForm()"></pc-button>
    </flex-row-end>
  </pc-overlay>

  <!-- confirmation (in progress) -->
  <pc-overlay *ngIf="billing.showConfirmationForm$ | async" [config]="confirmationDialog" (closeClick)="billing.showConfirmationForm$.next(false)">
    <ng-container body>
      <div style="width: 100%">
        <h3>{{ 'STORE.SUBSCRIPTION_ID' | translate }}</h3>
        <div style="font-size: 20px; margin-bottom: 20px">{{ billing.bookedSubscription.referenceCode }}</div>
        <app-cart-calculation [shoppingCart]="billing.billingDetails.shoppingCart"></app-cart-calculation>
      </div>
    </ng-container>

    <flex-row-end buttons style="justify-content: flex-end">
      <pc-button variant="secondary" [text]="'STORE.GO_TO_SERVICE' | translate" (btnClick)="submitConfirmationForm()"></pc-button>

      <div style="margin-left: 20px" *ngIf="getAssignWording(billing.bookedSubscription.smartServiceId) as wording">
        <pc-button [text]="wording" (btnClick)="onAssignButtonClick()"></pc-button>
      </div>
    </flex-row-end>
  </pc-overlay>

  <!-- tsd UPGRADE confirmation (in progress) -->
  <pc-overlay
    *ngIf="billing.showUpgradeConfirmationForm$ | async"
    [config]="{ title: 'Upgrade Confirmation' }"
    (closeClick)="billing.showUpgradeConfirmationForm$.next(false)">
    <ng-container body>
      <div style="width: 100%">
        <h3>{{ 'STORE.SUBSCRIPTION_ID' | translate }}</h3>
        <div style="font-size: 20px; margin-bottom: 20px">{{ billing.upgradedSubscription.referenceCode }}</div>
        <app-cart-calculation [tsdUpgrade]="true"></app-cart-calculation>
      </div>
    </ng-container>

    <flex-row-end buttons style="justify-content: flex-end">
      <pc-button variant="secondary" [text]="'STORE.GO_TO_SERVICE' | translate" (btnClick)="submitConfirmationForm()"></pc-button>

      <div style="margin-left: 20px">
        <pc-button [text]="getAssignWording('tsd')" (btnClick)="this.billing.showAssignMetricsModal$.next(true)"></pc-button>
      </div>
    </flex-row-end>
  </pc-overlay>

  <!-- contact us to upgrade modal -->
  <pc-overlay *ngIf="billing.showContactRequired$ | async" [config]="{ title: 'Please contact us' }" (closeClick)="billing.showContactRequired$.next(false)">
    <ng-container body>
      <div style="width: 100%; font-size: 20px">
        {{ 'STORE.CHANGE_INFO' | translate }}

        <div style="margin-top: 20px">
          <pc-button [text]="'STORE.CONTACT_US' | translate" (btnClick)="ui.openURL('https://proficloud.io/contact')"></pc-button>
        </div>
      </div>
    </ng-container>
  </pc-overlay>

  <!-- upgrade preview overlay -->
  <pc-overlay *ngIf="billing.showUpgradeDetails$ | async" [config]="{ title: 'Subscription Upgrade' }" (closeClick)="closeUpgradeDialog()">
    <ng-container body>
      <!-- row 1: current details -->
      <flex-row style="width: 100%">
        <flex-col style="width: 50%">
          <b>{{ 'STORE.SUBSCRIPTION_ID' | translate }}</b>
          <div>{{ billing.billingDetails.upgradePreview?.subscription.referenceCode }}</div>
        </flex-col>
        <flex-col style="width: 50%">
          <b>{{ 'STORE.BOOKED_ON' | translate }}</b>
          <div>{{ billing.billingDetails.upgradePreview?.subscription.startDate | date }}</div>
        </flex-col>
      </flex-row>

      <!-- upgrade section -->
      <div style="width: 100%; margin-top: 20px">
        <b>{{ 'STORE.PACKAGE_UPGRADE' | translate }}</b>
        <!-- container -->
        <flex-row style="background: #eee">
          <!-- left (old) -->
          <flex-col style="width: 50%; padding: 10px; box-sizing: border-box">
            <b>{{ 'STORE.PREVIOUS_PACKAGE' | translate }}</b>
            <div>{{ billing.billingDetails.upgradePreview?.oldSubscription.package.name }}</div>
            <div>
              {{ billing.billingDetails.upgradePreview?.oldSubscription.package.pricing.recurringFee }}
              {{ billing.billingDetails.upgradePreview?.oldSubscription.package.pricing.currency }}/{{
                billing.billingDetails.upgradePreview?.oldSubscription.package.contractTerms.billingPeriod
              }}
            </div>
          </flex-col>

          <!-- right (new) -->
          <flex-row style="width: 50%; background: linear-gradient(90deg, #0099a1 0%, #21b28b 100%); color: white; box-sizing: border-box">
            <svg viewBox="0 0 100 100" style="height: 100%; width: 20px" preserveAspectRatio="none">
              <path d="M0,0 L100,50 L0,100 z" fill="#eee"></path>
            </svg>

            <flex-col style="padding: 10px">
              <b>{{ 'STORE.NEW_PACKAGE' | translate }}</b>
              <div>{{ billing.billingDetails.upgradePreview?.newSubscription.package.name }}</div>
              <div>
                {{ billing.billingDetails.upgradePreview?.newSubscription.package.pricing.recurringFee }}
                {{ billing.billingDetails.upgradePreview?.newSubscription.package.pricing.currency }}/{{
                  billing.billingDetails.upgradePreview?.newSubscription.package.contractTerms.billingPeriod
                }}
              </div>
            </flex-col>
          </flex-row>
        </flex-row>
      </div>

      <!-- info text -->
      <div style="width: 100%; margin: 20px 0">
        {{ 'STORE.CONTRACT_DURATION_INFO' | translate }}
      </div>

      <!-- upgrade math -->
      <div style="background: #eee; padding: 10px; width: 100%; box-sizing: border-box">
        <!-- top row: icon and service name -->

        <!-- old plan and math -->
        <!-- use cmp instead -->
        <app-cart-calculation [tsdUpgrade]="true"></app-cart-calculation>
      </div>

      <!-- user reference & address... -->
      <div style="width: 100%; margin-top: 20px">
        <!-- middle: free text reference -->
        <pc-input-textfield
          [control]="billing.upgradeReferenceControl"
          placeholder="Your reference (optional)"
          size="small"
          variant="fit-width"
          (valueChanged)="billing.upgradeReference = $event"></pc-input-textfield>

        <flex-row-between style="margin-top: -30px">
          <flex-col style="width: 45%">
            <div>
              <h4>{{ 'STORE.BILLING_ADDRESS' | translate }}</h4>
              <div>{{ billing.billingAccount?.companyName }}</div>
              <div>{{ billing.billingAccount?.firstName }} {{ billing.billingAccount?.lastName }}</div>
              <div>{{ billing.billingAccount?.address.street }} {{ billing.billingAccount?.address.houseNumber }}</div>
              <div>{{ billing.billingAccount?.address.postalCode }} {{ billing.billingAccount?.address.city }}</div>
              <div>{{ billing.countryMapping[billing.currentCountry] }}</div>
            </div>
          </flex-col>

          <flex-col style="width: 45%">
            <div *ngIf="billing.getContractPartner() as contractPartner">
              <h4>{{ 'STORE.CONTRACT_PARTNER' | translate }}</h4>
              <div>{{ contractPartner.companyName }}</div>
              <div style="white-space: pre">{{ contractPartner.companyAddress }}</div>
            </div>
          </flex-col>
        </flex-row-between>

        <!-- bottom: billing address -->

        <h4>{{ 'STORE.CONFIRMATION_EMAIL' | translate }}</h4>
        <!-- t&c -->
        <flex-row>
          <!-- left: checkbox -->
          <div>
            <input type="checkbox" id="upgradeTermsCheck" #upgradeTermsCheck (change)="upgradeTermsAccepted = $event.target.checked" />
          </div>
          <!-- right: wording -->
          <div>
            <label
              for="termsCheckbox"
              style="cursor: default"
              [innerHtml]="
                'STORE.TERMS_AND_CONDITIONS' | translate: { param1: billing.links.termsAndConditions, param2: billing.links.softwareLicenseTerms }
              "></label>
          </div>
        </flex-row>
      </div>
      <!-- full width content -->

      <flex-row-center style="width: 100%; margin-top: 20px; justify-content: flex-end">
        <pc-button [wide]="true" [text]="'STORE.BOOK_UPGRADE' | translate" [disabled]="!upgradeTermsAccepted" (btnClick)="onConfirmUpgradeClick()"></pc-button>
      </flex-row-center>
    </ng-container>
  </pc-overlay>
</div>
