<div
  class="navBar"
  [@minimizeNavigation]="(navigationService.navigationToggled$ | async) ? 'minimized' : 'maximized'"
  (@minimizeNavigation.start)="navigationService.navigationAnimationStarted$.next(true)"
  (@minimizeNavigation.done)="navigationService.navigationAnimationFinished$.next(true)">
  <!-- top section -->

  <div class="navigation-item-container">
    <ng-container *ngFor="let navItem of navigationService.navItems; let i = index">
      <app-navigation-item [minimized]="navigationService.navigationToggled$ | async" [navItem]="navItem"></app-navigation-item>
    </ng-container>
  </div>

  <div>
    <!-- Footer Information -->
    <flex-col-center *ngIf="(navigationService.navigationToggled$ | async) === false" class="navigation_tos-container">
      <div>
        <a class="footer-item" style="margin-left: auto" (click)="proficloudService.mailtoSupport()">{{ 'APP.FOOTER_CONTACT' | translate }}</a>
        <span class="footer-divider">|</span>

        <a class="footer-item" [href]="environment.siteNoticeLink">{{ 'APP.FOOTER_SITE_NOTICE' | translate }}</a>
        @if (environment.dataPrivacyLink) {
          <span class="footer-divider">|</span>
          <a class="footer-item" [href]="environment.dataPrivacyLink">{{ 'APP.FOOTER_PRIVACY' | translate }}</a>
        }
      </div>
      <div>
        <span class="footer-item">{{ 'APP.FOOTER_BUILD' | translate: { param: proficloudService.appEnvironemnt.version } }}</span>
      </div>
      <div>
        <span class="footer-item">{{ environment.copyright }}</span>
      </div>
    </flex-col-center>
    <!-- Control Icons -->
    <flex-col-center class="navigation_control-container" [class.navigation_control-container--minimized]="navigationService.navigationToggled$ | async">
      <div
        *ngIf="navigationService.navigationToggled$ | async"
        (click)="toggleInfoPanel()"
        (clickOutside)="infoPanelVisible = false"
        class="navigation_control-icon"
        cdkOverlayOrigin
        #infoPanelTrigger="cdkOverlayOrigin">
        <app-icon [name]="'info'" [color]="'var(--jet-black)'"></app-icon>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="infoPanelTrigger"
          [cdkConnectedOverlayOpen]="infoPanelVisible"
          [cdkConnectedOverlayPositions]="positionPairs">
          <div *ngIf="navigationService.navigationToggled$ | async" class="navigation_control-minimized-content">
            <div class="navigation_control-minimized-content_links">
              <div class="navigation_control-minimized-content_title">
                {{ 'NAV.LEGALANDCONTACT' | translate }}
              </div>
              <div class="navigation_control-minimized-content_link" (click)="openLink(environment.siteNoticeLink)">
                {{ 'APP.FOOTER_SITE_NOTICE' | translate }}
              </div>
              <div class="navigation_control-minimized-content_link" (click)="openLink(environment.dataPrivacyLink)">
                {{ 'APP.FOOTER_PRIVACY' | translate }}
              </div>
              <div class="navigation_control-minimized-content_link" (click)="proficloudService.mailtoSupport()">
                {{ 'APP.FOOTER_CONTACT' | translate }}
              </div>
            </div>
            <div class="navigation_control-minimized-content_title">
              {{ environment.copyright }}
              {{ 'APP.FOOTER_BUILD' | translate: { param: proficloudService.appEnvironemnt.version } }}
            </div>
          </div>
        </ng-template>
      </div>

      <!--<div class="navigation_control-icon" [routerLink]="['/services/account/user-settings']">
        <app-icon [name]="'gear'" [color]="'var(--jet-black)'"></app-icon>
      </div>-->
      <div class="navigation_control-expand-icon" (click)="navigationService.toggleNavigation(); ui.popupPositionRecalculationRequired$.next(true)">
        <app-icon
          [class.navigation_control-expand-icon--minimized]="navigationService.navigationToggled$ | async"
          [name]="'expandIcon'"
          [color]="'var(--jet-black)'"></app-icon>
      </div>
    </flex-col-center>
  </div>
</div>
