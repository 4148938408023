import { Component } from '@angular/core'
import { NotificationService } from '@services/notification.service'

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent {
  notificationMenuVisible = false

  constructor(public notificationService: NotificationService) {}

  expandNotificationMenu($event: any) {
    if (this.notificationService.notDismissedNotifications.length > 1) {
      this.notificationService.showNotifications$.next(true)
      $event.stopPropagation()
    }
  }
}
