import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AuthGuardService } from '@services/auth-guard.service'
import { environment } from 'src/environments/environment'
import { AuthCallbackComponent, RegistrationCallbackComponent } from '../shared/components/auth-callback/auth-callback.component'
import { EmailChangeComponent } from '../shared/components/email-change/email-change.component'
import { ForgotPasswordComponent } from '../shared/components/forgot-password/forgot-password.component'
import { IamRegisterComponent } from '../shared/components/iam-register/iam-register.component'
import { InvitationCallbackComponent } from '../shared/components/invitation-callback/invitation-callback.component'
import { KitchenSinkComponent } from '../shared/components/kitchen-sink/kitchen-sink.component'
import { ResetPasswordComponent } from '../shared/components/reset-password/reset-password.component'
import { TesterLoginComponent } from '../shared/components/tester-login/tester-login.component'
import { AccountManagementComponent } from './components/account-management/account-management.component'
import { AuthenticationScreenComponent } from './components/authentication-screen/authentication-screen.component'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component'
import { ProficloudShellComponent } from './components/proficloud-shell/proficloud-shell.component'
import { RootComponent } from './components/root/root.component'
import { ServiceDetailComponent } from './components/services/service-detail/service-detail.component'
import { ServiceListComponent } from './components/services/service-list/service-list.component'
import { ServicesRootComponent } from './components/services/services-root/services-root.component'
import { UserSettingsComponent } from './components/user-settings/user-settings.component'
import { AdminServiceComponent } from './modules/admin-service/components/admin-service/admin-service.component'
import { DeviceManagementComponent } from './modules/device-management/components/device-management/device-management.component'
import { EnergyManagementComponent } from './modules/energy-management/components/energy-management/energy-management.component'
import { GatewayRootComponent } from './modules/gateway/components/gateway-root/gateway-root.component'
import { OcppProxyRouterComponent } from './modules/ocpp-proxy/components/ocpp-proxy-router/ocpp-proxy-router.component'
import { UserManagementComponent } from './modules/user-management/components/user-management/user-management.component'

const chargeRepayRoutes: Routes = [
  {
    path: '',
    data: { title: 'Charge Repay Service' },
    component: EnergyManagementComponent,
    loadChildren: () => import('./modules/charge-repay/charge-repay.module').then((m) => m.ChargeRepayModule),
  },
]

const gatewayRoutes: Routes = [
  {
    path: '',
    redirectTo: '/guide/entry',
    pathMatch: 'full',
  },
  {
    path: '',
    component: GatewayRootComponent,
    loadChildren: () => import('./modules/gateway/gateway.module').then((m) => m.GatewayModule),
  },
]

const proficloudRoutes: Routes = [
  {
    path: 'tester-login',
    component: TesterLoginComponent,
  },
  {
    path: 'kitchen-sink',
    component: KitchenSinkComponent,
  },
  {
    path: '',
    data: { title: 'Proficloud.io' },
    component: RootComponent,
    children: [
      {
        path: '',
        redirectTo: 'services/device-management/list',
        pathMatch: 'full',
      },
      {
        path: 'register',
        data: { title: 'NAV.REGISTER' },
        component: IamRegisterComponent,
      },
      {
        path: 'forgot-password',
        data: { title: 'NAV.FORGOT_PASSWORD' },
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        data: { title: 'NAV.RESET_PASSWORD' },
        component: ResetPasswordComponent,
      },
      {
        path: 'authenticate',
        data: { title: 'NAV.AUTHENTICATE' },
        component: AuthenticationScreenComponent,
      },
      {
        path: 'auth-callback',
        component: AuthCallbackComponent,
      },
      {
        path: 'registration-callback',
        component: RegistrationCallbackComponent,
      },
      {
        path: 'driver-registration-callback',
        component: RegistrationCallbackComponent,
      },
      {
        path: 'email-change',
        component: EmailChangeComponent,
      },
      {
        path: 'accept-invite',
        component: InvitationCallbackComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'services',
        component: ProficloudShellComponent,
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/404',
          },
          {
            path: '',
            data: { id: 'store-service', title: 'NAV.STORE' },
            component: ServicesRootComponent,
            children: [
              {
                path: 'store',
                data: { title: 'NAV.STORE', public: false },
                component: ServiceListComponent,
              },
              {
                path: 'store/:id',
                data: { title: 'NAV.STORE_SERVICE_DETAILS' },
                component: ServiceDetailComponent,
              },
              {
                path: '**',
                redirectTo: '404',
              },
            ],
          },
          {
            path: 'device-management',
            data: { id: 'device-management-service', title: 'NAV.DMS' },
            component: DeviceManagementComponent,
            loadChildren: () => import('./modules/device-management/device-management.module').then((m) => m.DeviceManagementModule),
          },
          {
            path: 'energy-management',
            data: { id: 'energy-management-service', title: 'NAV.EMMA' },
            component: EnergyManagementComponent,
            loadChildren: () => import('./modules/energy-management/energy-management.module').then((m) => m.EnergyManagementModule),
          },
          {
            path: 'charge-repay-service',
            data: { id: 'e-charging-service', title: 'Charge Repay Service' },
            component: EnergyManagementComponent,
            loadChildren: () => import('./modules/charge-repay/charge-repay.module').then((m) => m.ChargeRepayModule),
          },
          {
            path: 'user-management-service',
            data: { id: 'user-management-service', title: 'NAV.USER_MANAGEMENT' },
            component: UserManagementComponent,
            loadChildren: () => import('./modules/user-management/user-management.module').then((m) => m.UserManagementModule),
          },
          {
            path: 'ocpp-proxy',
            data: { id: 'ocpp-proxy', title: 'NAV.OCPP' },
            component: OcppProxyRouterComponent,
            loadChildren: () => import('./modules/ocpp-proxy/ocpp-proxy.module').then((m) => m.OcppProxyModule),
          },
          {
            path: 'admin-service',
            data: { id: 'admin-service', title: 'NAV.ADMIN_SERVICE' },
            component: AdminServiceComponent,
            loadChildren: () => import('./modules/admin-service/admin-service.module').then((m) => m.AdminServiceModule),
          },
          {
            path: 'account',
            component: AccountManagementComponent,
            data: { title: 'NAV.PROFILE' },
            children: [
              {
                path: 'user-settings',
                component: UserSettingsComponent,
                data: { title: 'NAV.PROFILE_SETTINGS' },
              },
              {
                path: '**',
                redirectTo: '/404',
              },
            ],
          },
        ],
      },
      {
        path: '404',
        component: PageNotFoundComponent,
      },
      {
        path: '**',
        redirectTo: '404',
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
]

/**
 *   The cloud codebase has 3 distinct applications:
 *
 *   - app.proficloud.io   (all of user-facing proficloud)
 *   - app.charge-repay.io (driver faceing charge repay app)
 *   - charge-repay.local  (running on the gateway locally for configuration)
 *
 *   This function determines which app to serve, why taking app specific routes
 *   base on the hostname where the code is served.
 */
function detemineRoutes() {
  let routes = proficloudRoutes
  if (environment.chargeRepay || window.location.host === 'localhost:3180' || window.location.host.includes('app.charge-repay')) {
    routes = chargeRepayRoutes
  }
  if (
    environment.gateway ||
    window.location.host === 'localhost:5555' ||
    window.location.host === '127.0.0.1:5555' ||
    window.location.host.includes('charge-repay.local') ||
    window.location.host.includes('charge-repay.cc')
  ) {
    routes = gatewayRoutes
  }
  return routes
}

const routes = detemineRoutes()

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProficloudRoutingModule {
  constructor(public translate: TranslateService) {}
}
