import { animate, state, style, transition, trigger } from '@angular/animations'

export const minimizingNavItemAnimation = trigger('minimizeNavigationItem', [
  state(
    'minimized',
    style({
      width: 56,
    })
  ),
  state(
    'maximized',
    style({
      width: 250,
    })
  ),
  transition('minimized <=> maximized', [animate('0.2s linear')]),
])
