{
  "colors": [
    "#BAC3E3",
    "#F7F7DC",
    "#DBE7E3",
    "#A3CEB1",
    "#E8D3B6",
    "#E0BCC6",
    "#A3AEC0",
    "#ADC0DE",
    "#ABDDDE",
    "#F0E7BB",
    "#F7EBA8",
    "#E7CCF6",
    "#F6F5F3",
    "#C1E3FE",
    "#C9DECE",
    "#FEB07C",
    "#F9CE90",
    "#B8D1C0",
    "#EC9BB0",
    "#E6D5C3",
    "#869F9F",
    "#B3C28A",
    "#7D96B0",
    "#94C0C0",
    "#D2C7BD"
  ]
}
