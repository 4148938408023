<flex-col style="height: 100%">
  <flex-col class="user-settings">
    <ng-scrollbar [autoHeightDisabled]="true">
      <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" class="custom-tabs" [selectedIndex]="selectdTabIndex" style="padding: 0px 15px">
        <!-- Organizations -->
        <mat-tab label="Organizations">
          <!-- <div *templateMarker> -->
          <div class="user_settings__topic" *ngIf="activeOrganisation">
            <app-expandable-organisation
              [organisationMenuOpened$]="organisationMenuOpened$"
              [organisation]="activeOrganisation"
              [active]="true"
              [authorised]="authorization.isAdmin(proficloud.currentOrganisation)"
              [id]="'settings-active-organization-detail-view'"></app-expandable-organisation>
          </div>
          <!-- </div> -->
        </mat-tab>

        <!-- Certificates -->
        <mat-tab label="Certificates">
          <!-- <div *templateMarker> -->
          <!-- both: certificate and account deletion -->
          <div class="user_settings__topic">
            <ng-container *ngIf="!authorization.isAdmin(this.proficloud.currentOrganisation); else isAdmin">
              <div class="user-settings_no-admin">You are not an Administrator and therefore cannot download or see the certificates of this Organization.</div>
            </ng-container>
            <ng-template #isAdmin>
              <!-- download certificate -->
              <div class="paper-1 user-settings__card">
                <div>
                  {{ 'USR_SETTINGS.CERTIFICATES_INFO' | translate }}
                  <a id="downloadAnchorElem"></a>
                </div>
                <div>
                  <pc-button
                    id="download-ca-certificate"
                    variant="secondary"
                    [text]="'USR_SETTINGS.CERTIFICATES_DOWNLOAD' | translate"
                    (btnClick)="proficloud.downloadCertificate()"></pc-button>
                </div>
              </div>
            </ng-template>
          </div>
          <!-- </div> -->
        </mat-tab>

        <!-- Account settings-->
        <mat-tab label="Account settings">
          <div class="user_settings__topic">
            <!-- download certificate -->
            <div class="paper-1 user-settings__card">
              <!-- left: content -->
              <flex-col>
                <div class="user_settings__account-personal-settings">
                  <div class="user_settings__account-label" style="margin-bottom: 8px">
                    {{ 'USR_SETTINGS.PERSONAL_DATA' | translate }}
                  </div>
                  <div class="user_settings__account-container">
                    <div class="user_settings__account-label">
                      {{ 'USR_SETTINGS.NAME' | translate }}
                    </div>
                    <div class="user_settings__account-value">
                      {{ proficloud.keycloakData.userDetails?.data.firstName }}
                      {{ proficloud.keycloakData.userDetails?.data.lastName }}
                    </div>
                  </div>
                  <div class="user_settings__account-container">
                    <div class="user_settings__account-label">
                      {{ 'USR_SETTINGS.EMAIL' | translate }}
                    </div>
                    <div class="user_settings__account-value">
                      {{ proficloud.keycloakData.userDetails?.data.email }}
                    </div>
                  </div>
                  <div *ngIf="chinaMode" class="user_settings__account-container">
                    <div class="user_settings__account-label">
                      {{ 'USR_SETTINGS.LOCALE' | translate }}
                    </div>
                    <div class="user_settings__account-value">
                      {{ proficloud.keycloakData.userDetails?.data.attributes.locale[0] | languageName }}
                    </div>
                  </div>
                </div>
              </flex-col>
              <div class="user_settings__account-container">
                <pc-button
                  variant="secondary"
                  size="small"
                  (btnClick)="overlay = dialogs.editProfile"
                  text="{{ 'USR_SETTINGS.CHANGE_INFORMATION' | translate }}"></pc-button>
              </div>
              <div class="user_settings__account-label">
                {{ 'USR_SETTINGS.DANGER_ZONE' | translate }}
              </div>
              <div>
                <div class="user_settings__account-container">
                  <pc-button
                    size="small"
                    variant="deletion"
                    [text]="'USR_SETTINGS.ACCOUNT_DELETION' | translate"
                    (btnClick)="overlay = dialogs.read"></pc-button>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-scrollbar>

    <app-billing-address-overlay
      *ngIf="billing.showBillingAddressForm$ | async"
      (closeClick)="billing.showBillingAddressForm$.next(false)"
      (submitClick)="billing.billingAccount ? billing.updateBillingAccount() : billing.createBillingAccount()">
    </app-billing-address-overlay>

    <!-- End of new layout -->

    <!-- Old layout -->
    <!-- only mobile -->
    <div *ngIf="ui.uiData.isMobile">
      <h2 class="semibold">{{ 'USR_SETTINGS.GENERAL_INFORMATION' | translate }}</h2>

      <flex-row-between>
        <!-- left: content -->
        <flex-col>
          <div class="flexRowBetween">
            {{ proficloud.keycloakData.userDetails?.data.firstName }}
            {{ proficloud.keycloakData.userDetails?.data.lastName }}
          </div>

          <div>{{ proficloud.keycloakData.userDetails?.data.email }}</div>
        </flex-col>

        <br /><br /><br />

        <!-- right: edit icon -->
        <app-icon
          id="edit-general-information"
          (click)="overlay = dialogs.editProfile"
          name="edit"
          [color]="'#333'"
          style="width: 40px; height: 30px; cursor: pointer"></app-icon>
      </flex-row-between>
    </div>
  </flex-col>
</flex-col>

<!-- STEP 1: Read dialog -->
<pc-overlay *ngIf="overlay === dialogs.read" [config]="dialogs.read" (closeClick)="overlay = undefined">
  <!-- BODY -->
  <div body>
    <div>{{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_READ_DIALOG_1' | translate }}</div>
    <div class="user-deletion-warning-space">
      {{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_READ_DIALOG_2' | translate }}
    </div>
    <div class="user-deletion-warning-space">
      {{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_READ_DIALOG_3' | translate }}
    </div>
    <ul style="font-size: 16px; line-height: 20px; padding-inline-start: 20px">
      <li>
        {{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_READ_DIALOG_4' | translate }}
      </li>
      <li>{{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_READ_DIALOG_5' | translate }}</li>
      <li>{{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_READ_DIALOG_6' | translate }}</li>
    </ul>
  </div>

  <!-- BUTTONS -->
  <flex-row buttons class="user_settings__account-deletion">
    <pc-button size="small" [text]="'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_DIALOG_KEEP' | translate" (btnClick)="overlay = undefined"></pc-button>

    <pc-button
      id="account-deletion-01"
      [disabled]="proficloud.keycloakData.userDetails?.data.email.includes('demo')"
      size="small"
      variant="deletion"
      [text]="'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_DIALOG_DELETE' | translate"
      (btnClick)="overlay = dialogs.confirm"></pc-button>
  </flex-row>
</pc-overlay>

<!-- STEP 2 confirm dialog -->
<pc-overlay *ngIf="overlay === dialogs.confirm" [config]="dialogs.confirm" (closeClick)="overlay = undefined">
  <!-- BODY -->
  <div body style="width: 100%">
    <div style="margin-bottom: 10px">
      {{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_CONFIRM_DIALOG_1' | translate }}

      <br /><br />
      {{ 'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_CONFIRM_DIALOG_2' | translate }}
    </div>

    <form [formGroup]="confirmDeleteForm">
      <pc-input-textfield [control]="confirmDeleteControl" [placeholder]="'Email'" variant="fit-width" label="Email"></pc-input-textfield>
    </form>
  </div>

  <!-- BUTTONS -->
  <flex-row buttons class="user_settings__account-deletion">
    <pc-button size="small" [text]="'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_DIALOG_KEEP' | translate" (btnClick)="overlay = undefined"></pc-button>

    <pc-button
      id="account-deletion-02"
      size="small"
      variant="deletion"
      [text]="'USER_MANAGEMENT_SERVICE.DELETE_ACCOUNT_DIALOG_DELETE' | translate"
      (btnClick)="deleteAccount()"></pc-button>
  </flex-row>
</pc-overlay>

<!-- success confirmation-->
<pc-overlay id="account-deletion-confirmation" *ngIf="overlay === dialogs.success" [config]="{ title: 'Success!' }" (closeClick)="overlay = undefined">
  <!-- BODY -->
  <div body style="width: 100%">
    <flex-row-center style="padding: 40px 20px">
      <app-icon name="checkCircleFilled" color="green" style="width: 60px; height: 60px"></app-icon>
    </flex-row-center>
    <div>
      {{ 'USR_SETTINGS.EMAIL_CHANGE_DIALOG_1' | translate }}
    </div>
    <div>{{ 'USR_SETTINGS.EMAIL_CHANGE_DIALOG_2' | translate }}</div>
  </div>
</pc-overlay>

<!-- profile editing -->
<pc-overlay *ngIf="overlay === dialogs.editProfile" [config]="dialogs.editProfile" (closeClick)="overlay = undefined">
  <!-- BODY -->
  <ng-container body>
    <form [formGroup]="updateUserForm" style="display: block; width: 100%">
      <div *ngFor="let field of updateUserFields" style="width: 100%; margin-bottom: 20px">
        <pc-input-textfield *ngIf="field.type === 'text'" [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-textfield>
      </div>
    </form>
  </ng-container>

  <!-- Buttons -->
  <flex-row-end buttons>
    <pc-button
      [disabled]="!updateUserForm?.valid || proficloud.keycloakData.userDetails?.data.email.includes('demo')"
      size="small"
      [text]="'USR_SETTINGS.SAVE_PROFILE_DATA' | translate"
      (btnClick)="updateUserDetails()"></pc-button>
  </flex-row-end>
</pc-overlay>

<!-- edit profile succcess -->
<pc-overlay *ngIf="overlay === dialogs.editProfileSuccess" [config]="dialogs.editProfileSuccess" (closeClick)="overlay = undefined">
  <ng-container body>
    <div style="width: 100%; font-size: 24px">{{ 'USR_SETTINGS.PROFILE_UPDATE_SUCCESS' | translate }}</div>
  </ng-container>

  <flex-row-end buttons>
    <pc-button [text]="'USR_SETTINGS.PROFILE_UPDATE_CLOSE' | translate" (btnClick)="overlay = undefined"></pc-button>
  </flex-row-end>
</pc-overlay>

<!-- Confirm email change -->
<pc-overlay *ngIf="overlay === dialogs.confirmEmailChange" [config]="dialogs.confirmEmailChange" (closeClick)="overlay = undefined">
  <!-- BODY -->
  <ng-container body>
    <div [innerHTML]="'USR_SETTINGS.EMAIL_CHANGE_CONFIRM' | translate: { param: updateUserForm.value.email }"></div>
  </ng-container>

  <flex-row-between buttons>
    <pc-button [text]="'USR_SETTINGS.CANCEL' | translate" (btnClick)="overlay = dialogs.editProfile"></pc-button>

    <pc-button variant="deletion" [text]="'USR_SETTINGS.CONFIRM' | translate" (btnClick)="updateUserDetails(true)"></pc-button>
  </flex-row-between>
</pc-overlay>

<!-- cancel subscription modal-->
<pc-overlay
  *ngIf="billing.showCancelSubscriptionConfirm$ | async as subscriptionInfo"
  [config]="{ title: 'Cancel Subscription' }"
  (closeClick)="billing.showCancelSubscriptionConfirm$.next(false)">
  <ng-container body>
    <div class="semibold" style="width: 100%; padding-bottom: 20px; font-size: 20px">
      {{ 'USR_SETTINGS.SUBSCRIPTION_CANCEL_ASK' | translate }}
    </div>

    <div style="background: #eee; padding: 20px; width: 100%; box-sizing: border-box; font-size: 18px">
      <div class="semibold">{{ 'USR_SETTINGS.PACKAGE' | translate }}:</div>
      <div *ngIf="billing.getPackageById(subscriptionInfo.subscription.bookedPackageId) as package">
        {{ package.name }}
      </div>
      <br />

      <!--
      here go subscription details, depending on the type of device

      <div style="margin: 0px 0;">Devices booked: <b>{{ subscriptionInfo.subscription.quantity }}</b>
            </div>

            <div style="margin: 0px 0;">Devices assigned: <b>{{
                subscriptionInfo.subscription.devicesUsed.length }}</b>
            </div>

            <div style="margin: 0px 0; ">Devices available: <b>{{ subscription.quantity -
                subscription.devicesUsed.length }}</b></div>

      <br> -->

      <div class="semibold">{{ 'USR_SETTINGS.DESCRIPTION' | translate }}:</div>
      <div style="margin: 0px 0">
        {{ billing.getPackageById(subscriptionInfo.subscription.bookedPackageId)?.description }}
      </div>

      <br />

      <div class="semibold" style="margin: 0px 0">Subscription ID:</div>
      <div>{{ subscriptionInfo.subscription.referenceCode }}</div>

      <br />

      <div class="semibold" style="margin-bottom: 0px">Booked at:</div>
      <div>{{ subscriptionInfo.subscription.startDate | date }}</div>
    </div>

    <div style="font-size: 18px; padding: 10px 0">
      {{ 'USR_SETTINGS.SUBSCRIPTION_CANCEL_USE_TILL_END' | translate }}
    </div>

    <div *ngIf="subscriptionInfo.type === 'emma'" style="font-size: 18px; padding: 10px 0">
      {{ 'USR_SETTINGS.SUBSCRIPTION_CANCEL_EMMA' | translate }}
    </div>

    <div style="width: 100%; font-size: 20px">
      <flex-row-between style="margin-top: 20px">
        <pc-button [text]="'USR_SETTINGS.SUBSCRIPTION_CANCEL_KEEP' | translate" (btnClick)="billing.showCancelSubscriptionConfirm$.next(false)"></pc-button>

        <pc-button
          [disabled]="authorization.isViewer(proficloud.currentOrganisation)"
          variant="secondary"
          [text]="'USR_SETTINGS.SUBSCRIPTION_CANCEL_CANCEL' | translate"
          (btnClick)="
            billing.showCancelSubscriptionConfirm$.next(false); billing.cancelSubscription(subscriptionInfo.subscription.id, subscriptionInfo.type)
          "></pc-button>
      </flex-row-between>
    </div>
  </ng-container>
</pc-overlay>
