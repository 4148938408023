import { Component, Input, OnInit } from '@angular/core'
import { UiService } from '@services/ui/ui.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'
import { watched } from 'src/app/modules/shared/decorators/watch-stream-decorator'
import { ClassDoc } from 'src/app/modules/shared/services/tracing/tracing.interfaces'
import { TracingService } from 'src/app/modules/shared/services/tracing/tracing.service'
import { sharedAnimations } from 'src/app/modules/shared/shared.animations'
import { DeviceService } from '../../../modules/device-management/services/device.service'
import { BillingService } from '../../../services/billing/billing.service'
import { Device, DeviceInfo, ExpansionStates } from '../../../services/proficloud.interfaces'
import { ProficloudService } from '../../../services/proficloud.service'

@Component({
  selector: 'app-subscription-metrics-modal',
  animations: [sharedAnimations.heightSlide],
  templateUrl: './subscription-metrics-modal.component.html',
  styleUrls: ['./subscription-metrics-modal.component.scss'],
})
export class SubscriptionMetricsModalComponent implements OnInit {
  classDoc: ClassDoc = {
    name: 'SubscriptionMetricsModalComponent',
    location: '',
  }

  @Input() singleDevice: Device | true // when true, we show everything

  tempAssignedMetrics: Record<string, string[]> = {}

  countTempAssignedMetrics() {
    return Object.entries(this.tempAssignedMetrics)
      .map(([key, val]) => val.length)
      .reduce((total, next) => total + next, 0)
  }

  expansionState: Record<string, ExpansionStates> = {}

  constructor(
    public billing: BillingService,
    public proficloud: ProficloudService,
    public deviceService: DeviceService,
    public tracing: TracingService,
    public ui: UiService
  ) {
    this.billing.setAllAvailableEndpointMetrics()
    this.tracing.registerInstance(this)
    ;(window as any).modal = this
  }

  ngOnInit(): void {
    this.tsdDevices().forEach((device) => {
      this.tempAssignedMetrics[device.endpointId] = JSON.parse(JSON.stringify(device.usedMetrics))
    })
    this.tsdDevices().forEach((device) => {
      this.expansionState[device.endpointId] = 'expanded'
    })
  }

  endpointToggle(checked: boolean, device: DeviceInfo) {
    if (checked) {
      this.tempAssignedMetrics[device.endpointId] = device.availableMetrics || []
    } else {
      this.tempAssignedMetrics[device.endpointId] = []
    }
  }

  toggleMetric(checked: boolean, endpointId: string, metric: string) {
    if (checked && !this.tempAssignedMetrics[endpointId].includes(metric)) {
      this.tempAssignedMetrics[endpointId].push(metric)
    }
    if (!checked && this.tempAssignedMetrics[endpointId].includes(metric)) {
      this.tempAssignedMetrics[endpointId] = this.tempAssignedMetrics[endpointId].filter((m) => m !== metric)
    }
  }

  tsdDevices() {
    return (this.deviceService.devices || []).filter((device) => this.deviceService.deviceIsTsdCapable(device))
  }

  queryTsdDevices(query: string) {
    const allTsdDevices = this.tsdDevices()
    return allTsdDevices.filter((device) => {
      return (
        device.metadata.deviceName.toLocaleLowerCase().includes(query.toLowerCase()) ||
        device.metadata.uuid?.toLowerCase().includes(query.toLowerCase()) ||
        device.availableMetrics?.some((m) => m.toLowerCase().includes(query.toLowerCase()))
      )
    })
  }

  filteredTsdDevices$ = new BehaviorSubject<DeviceInfo[]>(this.tsdDevices())

  @watched()
  filterInput$ = new Subject()

  @watched()
  filterTap$ = this.filterInput$.pipe(
    tap((query: string) => {
      const filtered = this.queryTsdDevices(query)
      this.filteredTsdDevices$.next(filtered)
    })
  )

  /**
   * Metrics querying
   */
  metricQuery = ''

  @watched()
  metricFilterInput$ = new Subject()

  @watched()
  metricFilterTap$ = this.metricFilterInput$.pipe(
    tap((query: string) => {
      this.metricQuery = query
    })
  )

  deviceAvailableAndFilteredMetrics(device: DeviceInfo, query: string = this.metricQuery) {
    const parts = query.split(' ')
    return (device.availableMetrics || []).filter((metric) => {
      return parts.every((part) => metric.toLowerCase().includes(part.toLowerCase()))
    })
  }

  showDevice(endpointId: string) {
    if (this.singleDevice && this.singleDevice !== true) {
      // single device case: only show one
      return endpointId === this.singleDevice.endpointId
    } else {
      // all devices case: show all
      return true
    }
  }

  someMetricsAssigned(device: DeviceInfo) {
    const notNone = this.tempAssignedMetrics[device.endpointId]?.length > 0
    const notAll = this.tempAssignedMetrics[device.endpointId]?.length < (device.availableMetrics?.length || 0)
    return notNone && notAll
  }

  allMetricsAssigned(device: DeviceInfo) {
    return this.tempAssignedMetrics[device.endpointId]?.length === device.availableMetrics?.length
  }

  assignMetricsClick() {
    const payload: { metrics: Record<string, string[]>[] } = { metrics: [] }
    Object.entries(this.tempAssignedMetrics).forEach(([endpointId, metrics]) => {
      payload.metrics.push({
        [endpointId]: metrics,
      })
    })

    this.bulkUpdateMetrics(payload)
  }

  bulkUpdateMetrics(payload: any) {
    this.billing.showAssignMetricsModal$.next(false)
    this.billing.bulkAssignMetrics(payload)
  }
}
