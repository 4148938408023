<div class="nav-item" *ngIf="navItem | navItemVisible" (clickOutside)="childrenVisible = false">
  <flex-col
    class="nav-item_container"
    [class.nav-item_container--minimized]="minimized"
    [@minimizeNavigationItem]="(navigation.navigationToggled$ | async) ? 'minimized' : 'maximized'"
    (mouseenter)="navItem.hover = !ui.isTouchScreen()"
    (mouseleave)="navItem.hover = false"
    (click)="openRoute()"
    [id]="navItem.id"
    [attr.data-analytics]="navItem.analyticsTag"
    cdkOverlayOrigin
    #childPanelTrigger="cdkOverlayOrigin">
    <div class="nav-item_content" [class.nav-item_content--minimized]="minimized">
      <div class="nav-item_icon-container">
        <app-icon
          [name]="navItem.icon"
          class="nav-item_icon"
          [color]="navItem.id === ui.routeServiceId || navItem.hover ? 'var(--navigation-hover)' : 'var(--jet-black)'"></app-icon>
      </div>

      <div *ngIf="!minimized" class="nav-item_inner-content">
        <!-- left: name -->
        <div
          class="nav-item_title-container"
          [class.nav-item_title-container--minimized]="navigation.navigationAnimationRunning$ | async"
          [class.nav-item_title-container--active]="navItem.id === ui.routeServiceId || navItem.hover">
          <a *ngIf="navItem.link">
            {{ navItem.name | translate }}
          </a>
          <span *ngIf="!navItem.link">
            {{ navItem.name | translate }}
          </span>
        </div>

        <app-icon
          *ngIf="navItem | navItemHasVisibleChild"
          class="nav-item_extend-icon"
          [name]="navItem.id === ui.routeServiceId ? 'chevronDown' : 'chevronRight'"
          [color]="navItem.id === ui.routeServiceId || navItem.hover ? 'var(--navigation-hover)' : 'var(--jet-black)'"></app-icon>
      </div>
    </div>

    <!--- right: bar -->
    <div class="nav-item_indicator" [class.nav-item_indicator--active]="navItem.id === ui.routeServiceId || navItem.hover"></div>
  </flex-col>
  <ng-container *ngIf="navItem.children && navItem.showChildrenAt === ui.routeServiceId && !minimized">
    <ng-container *ngFor="let child of navItem.children; let i = index; first as isFirst">
      <flex-row
        *ngIf="child | navItemChildVisible"
        style="font-size: 16px"
        class="child-item_container"
        [class.child-item_controller]="isFirst"
        [routerLink]="[child.routerLink]"
        [id]="child.id"
        [attr.data-analytics]="child.analyticsTag">
        <div class="{{ window.location.pathname === child.routerLink ? 'item_activated' : '' }} child-item">
          {{ child.name }}
        </div>
        <!-- right: bar -->
        <div class="styles-navItem-container-right">
          <div
            class="styles-navItem-container-right-bar"
            [style.background]="window.location.pathname === child.routerLink ? 'var(--navigation-hover)' : 'white'"></div>
        </div>
      </flex-row>
    </ng-container>
  </ng-container>

  <ng-template
    *ngIf="navItem.children && minimized && (navigation.navigationAnimationRunning$ | async) === false"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="childPanelTrigger"
    [cdkConnectedOverlayOpen]="childrenVisible"
    [cdkConnectedOverlayPositions]="positionPairs">
    <div class="child-item_container--minimized">
      <div class="child-item_container-title--minimized child-item_container-title--minimized-first">
        {{ navItem.name | translate }}
      </div>
      <ng-container *ngFor="let child of navItem.children; let last = last">
        <flex-row
          *ngIf="child | navItemChildVisible"
          class="child-item--minimized"
          [class.child-item--minimized-active]="window.location.pathname === child.routerLink"
          [class.child-item--minimized-last]="last"
          [routerLink]="[child.routerLink]"
          [id]="child.id"
          [attr.data-analytics]="child.analyticsTag">
          <div class="{{ window.location.pathname === child.routerLink ? 'item_activated' : '' }}">
            {{ child.name }}
          </div>
        </flex-row>
      </ng-container>
    </div>
  </ng-template>
</div>
