@if (notificationService.notDismissedNotifications.length > 0) {
  <div class="notificationBell">
    <span
      (click)="expandNotificationMenu($event)"
      [matBadge]="notificationService.notDismissedNotifications.length"
      matBadgeOverlap="true"
      matBadgePosition="after"
      matBadgeSize="small"
      [matBadgeHidden]="notificationService.notDismissedNotifications.length < 1">
      <app-icon name="notification_bell_closed" color="#000"></app-icon>
    </span>
  </div>
}
