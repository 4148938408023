import { ConnectionPositionPair } from '@angular/cdk/overlay'
import { Component, inject, ViewChild } from '@angular/core'
import { environment } from '@environments/environment'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { minimizingAnimation } from './navigation.animations'
import { NavigationService } from './navigation.service'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [minimizingAnimation],
})
export class NavigationComponent {
  proficloudService = inject(ProficloudService)
  navigationService = inject(NavigationService)

  @ViewChild('infoPanelTrigger') infoPanelTrigger: any

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 17,
      offsetY: 10,
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ]

  infoPanelVisible = false

  constructor(public ui: UiService) {}

  openLink(link: string) {
    window.open(link)
  }

  toggleInfoPanel() {
    this.infoPanelVisible = !this.infoPanelVisible
  }

  protected readonly environment = environment
}
