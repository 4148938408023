import { Component } from '@angular/core'
import { UiService } from '@services/ui/ui.service'
import { AppService } from 'src/app/app.service'

@Component({
  selector: 'app-public-header',
  template: `
    <flex-row-start-center
      class="paper-1"
      style="cursor: default; z-index: 5; position: fixed; left: 0; top: 0; width: 100%; height: 80px; box-sizing: border-box; background: white;">
      <app-proficloud-logo style="width: 170px; display: inline-block; margin: 0px 20px 0 40px"></app-proficloud-logo>

      <div style="width:1px; background: #ccc; height: 70%; margin: 0px 36px;"></div>

      <!-- 2 items -->
      <ng-container *ngFor="let item of ui.uiData.isMobile ? [navItems[1]] : liveNavItem()">
        <a
          (mouseenter)="item.hover = true"
          (mouseleave)="item.hover = false"
          [href]="item.link"
          style="text-decoration: none; display: flex; flex-direction: column; justify-content: center; cursor: pointer; margin-right: 50px; font-size: 20px; color: #0098A1; position: relative; height: 100%;">
          {{ item.name }}
          <div *ngIf="item.active || item.hover" style="position: absolute; bottom: 0; left: 0; height: 4px; width: 100%; background: #0098A1;"></div>
        </a>
      </ng-container>
    </flex-row-start-center>
  `,
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent {
  navItems = [
    {
      name: 'Proficloud',
      active: true,
      hover: false,
      link: '/',
    },
    {
      name: 'Service Store',
      active: false,
      hover: false,
      link: 'https://proficloud.io/smart-service-store/',
      hideInProduction: false,
    },
    {
      name: 'FAQ',
      active: false,
      hover: false,
      link: 'https://proficloud.io/faqs',
    },
    {
      name: 'News',
      active: false,
      hover: false,
      link: 'https://proficloud.io/newsblog/',
    },
  ]

  liveNavItem() {
    if (this.app.environment.frontend === 'production') {
      return this.navItems.filter((item) => !item.hideInProduction)
    } else {
      return this.navItems
    }
  }

  constructor(
    public ui: UiService,
    public app: AppService
  ) {}
}
