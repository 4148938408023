<div class="paper-1 expandable-organization">
  <div class="expandable-organization__header" (click)="toggleExpansion()">
    <div>
      <div class="expandable-organization__title-section">
        <div class="expandable-organization__title">
          {{ organisation.organizationName | truncate: [110] }}
        </div>
        <div class="expandable-organization__title--hint" *ngIf="active">
          {{ 'USR_SETTINGS.ACTIVE_ORGANIZATION' | translate }}
        </div>
      </div>
      <div class="expandable-organization__subtitle">
        {{ organisation.organizationId }}
      </div>
    </div>

    <flex-row class="section">
      <flex-row>
        <div-relative class="icon">
          <app-icon
            name="more"
            style="cursor: pointer"
            (click)="toggleOrganizationMenu($event)"
            color="#202020"
            [id]="'settings-organization-settings-icon-' + organisation.organizationId"></app-icon>
          <div
            *ngIf="this.organizationMenuVisible"
            [id]="'settings-organization-settings'"
            (clickOutside)="this.organizationMenuVisible = false"
            class="paper-2 organizationItemMenu">
            <flex-row-start-center
              class="organizationItemMenuItem {{ !authorisation.isAdmin(organisation) ? 'disabled' : '' }}"
              style="cursor: pointer"
              [id]="'edit'"
              (click)="openRenameOrgDialog($event)">
              <app-icon class="icon" name="edit" color="{{ !authorisation.isAdmin(organisation) ? 'var(--regent-gray)' : '#0098a1' }}"> </app-icon>
              {{ 'USER_MANAGEMENT_SERVICE.RENAME_ORGANIZATION' | translate }}
            </flex-row-start-center>
            <flex-row-start-center
              class="organizationItemMenuItem"
              style="cursor: pointer"
              [id]="'leave'"
              (click)="showLeaveOrg = true; stopEventPropagation($event); this.organizationMenuVisible = false">
              <app-icon class="icon" name="logout" color="#0098a1"></app-icon>
              {{ 'USER_MANAGEMENT_SERVICE.LEAVE_ORGANIZATION' | translate }}
            </flex-row-start-center>
          </div>
        </div-relative>
      </flex-row>
    </flex-row>
  </div>

  <div style="overflow: hidden" *ngIf="active && authorised">
    <div class="expandable-organization__details">
      <!-- Tab headers -->
      <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" class="custom-tabs">
        <!-- <pc-tab-bar [tabs]="tabs"> -->
        <!-- Information tab -->
        <mat-tab label="Information">
          <!-- <flex-col *templateMarker> -->
          <div *ngIf="members?.length">
            <table>
              <tr>
                <td style="min-width: 200px">{{ 'USER_MANAGEMENT_SERVICE.CREATOR' | translate }}</td>
                <td>
                  {{ this.organisation.organizationDetails?.organizationCreator?.firstName }}
                  {{ this.organisation.organizationDetails?.organizationCreator?.lastName }}
                </td>
              </tr>
              <tr>
                <td style="min-width: 200px">{{ 'USER_MANAGEMENT_SERVICE.MEMBERS' | translate }}</td>
                <td>{{ members?.length }}</td>
              </tr>
            </table>
          </div>
          <!-- </flex-col> -->
        </mat-tab>
        <!-- Subscriptions tab -->
        <mat-tab label="Subscriptions">
          <!-- <flex-col *templateMarker> -->
          <ng-container *ngIf="proficloud.currentOrganisation === organisation">
            <!-- DMS BA Multiple possible -->
            <mat-expansion-panel hideToggle class="paper-1 serviceSubscriptionList" *ngIf="deviceBilling.subscriptions?.length">
              <mat-expansion-panel-header>
                <flex-col>
                  <mat-panel-title class="subscriptionTypeName">
                    {{ 'USR_SETTINGS.DMS_BASIC_ADD_ON' | translate }}
                  </mat-panel-title>
                  <div>
                    {{
                      'USR_SETTINGS.GENERAL_SLOT_INFORMATION'
                        | translate
                          : {
                              param1: deviceBilling.countSubscribedDevices(),
                              param2: deviceBilling.countSubscriptionSlots()
                            }
                    }}
                  </div>
                </flex-col>
              </mat-expansion-panel-header>
              <app-subscription-information
                *ngFor="let subscription of deviceBilling.subscriptions"
                [subscription]="subscription"
                subscriptionType="dmsAddonBasic">
              </app-subscription-information>
            </mat-expansion-panel>

            <!-- TSD Service Only One Subscription -->
            <!-- NOTE(JanLippemeier) This is a hack for edge case with prologis TSD (the part with && billing.bookedTsdPackage),
                this needs specification by Florian Jacob once prologis TSD is ready to ship -->
            <mat-expansion-panel
              data-analytics="usr-settings-tsd-subscription-panel"
              hideToggle
              class="paper-1 serviceSubscriptionList"
              *ngIf="billing.tsdSubscription && billing.bookedTsdPackage">
              <mat-expansion-panel-header>
                <flex-col>
                  <mat-panel-title class="subscriptionTypeName">
                    {{ 'USR_SETTINGS.TSDSERVICE' | translate }}
                  </mat-panel-title>
                  <div data-analytics="usr-settings-tsd-slot-information">
                    {{
                      'USR_SETTINGS.TSD_SLOT_INFORMATION'
                        | translate
                          : {
                              param1: billing.tsdToalMetricsUsed,
                              param2: billing.getTsdPackageById(billing.tsdSubscription.bookedPackageId)?.maxNumberOfMetrics
                            }
                    }}
                  </div>
                </flex-col>
              </mat-expansion-panel-header>
              <app-subscription-information [subscription]="billing.tsdSubscription" subscriptionType="tsd"> </app-subscription-information>
            </mat-expansion-panel>

            <!-- impulse analytics (multiple possible) -->
            <mat-expansion-panel
              hideToggle
              class="paper-1 serviceSubscriptionList"
              *ngIf="billing.impulseAnalyticsSubscriptions && billing.impulseAnalyticsSubscriptions.length > 0">
              <mat-expansion-panel-header>
                <flex-col>
                  <mat-panel-title class="subscriptionTypeName">
                    {{ 'USR_SETTINGS.IA_SERVICE' | translate }}
                  </mat-panel-title>
                  <div>
                    {{
                      'USR_SETTINGS.GENERAL_SLOT_INFORMATION'
                        | translate
                          : {
                              param1: impulseSlotSummary().usedSlots,
                              param2: impulseSlotSummary().availableSlots
                            }
                    }}
                  </div>
                </flex-col>
              </mat-expansion-panel-header>
              <app-subscription-information
                *ngFor="let subscription of billing.impulseAnalyticsSubscriptions"
                [subscription]="subscription"
                subscriptionType="ia">
              </app-subscription-information>
            </mat-expansion-panel>

            <!-- EMMA (multiple possible) -->
            <mat-expansion-panel hideToggle class="paper-1 serviceSubscriptionList" *ngIf="billing?.emmaSubscriptions?.length && billing.emmaPackages?.length">
              <mat-expansion-panel-header>
                <flex-col>
                  <mat-panel-title class="subscriptionTypeName">
                    {{ 'USR_SETTINGS.EMMA_SERVICE' | translate }}
                  </mat-panel-title>
                  <div>
                    {{
                      'USR_SETTINGS.GENERAL_SLOT_INFORMATION'
                        | translate
                          : {
                              param1: emma.currentlyAssignedDevicesForSubscription(),
                              param2: billing.subscribedEmmaDevicesTotal.totalSubscriptionSlots
                            }
                    }}
                  </div>
                </flex-col>
              </mat-expansion-panel-header>
              <app-subscription-information *ngFor="let subscription of billing.emmaSubscriptions" [subscription]="subscription" subscriptionType="emma">
              </app-subscription-information>
            </mat-expansion-panel>

            <!-- Charge Repay (multiple possible) -->
            <mat-expansion-panel hideToggle class="paper-1 serviceSubscriptionList" *ngIf="billing?.chargeRepaySubscriptions.length">
              <mat-expansion-panel-header>
                <flex-col>
                  <mat-panel-title class="subscriptionTypeName">
                    {{ 'USR_SETTINGS.CHARGE_REPAY_SERVICE' | translate }}
                  </mat-panel-title>
                  <div>
                    {{
                      'USR_SETTINGS.CHARGE_REPAY_SEAT_INFORMATION'
                        | translate
                          : {
                              param1: billing.chargeRepayStats.usedSeats,
                              param2: billing.chargeRepayStats.totalSeats
                            }
                    }}
                  </div>
                </flex-col>
              </mat-expansion-panel-header>
              <app-subscription-information
                *ngFor="let subscription of billing.chargeRepaySubscriptions"
                [subscription]="subscription"
                subscriptionType="chargeRepay">
              </app-subscription-information>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngIf="proficloud.currentOrganisation !== organisation">
            <flex-row> {{ 'USER_MANAGEMENT_SERVICE.PLEASE_SWITCH_ORGANIZATION_SUBSCRIPTION' | translate }} </flex-row>
          </ng-container>
          <!-- </flex-col> -->
        </mat-tab>
        <!-- Billing tab  -->
        <mat-tab label="Billing Information">
          <!-- <flex-col *templateMarker> -->
          <ng-container *ngIf="proficloud.currentOrganisation === organisation">
            <div>
              <div *ngIf="billing.billingAccount" style="margin-bottom: 40px">
                <div>{{ billing.billingAccount.companyName }}</div>
                <div>{{ billing.billingAccount.firstName }} {{ billing.billingAccount.lastName }}</div>
                <div>{{ billing.billingAccount.address.street }} {{ billing.billingAccount.address.houseNumber }}</div>
                <div>{{ billing.billingAccount.address.postalCode }} {{ billing.billingAccount.address.city }}</div>
                <div>{{ billing.billingAccount.address.country }}</div>
                <br />
                <div>{{ billing.billingAccount.emailAddress }}</div>
                <div style="margin-bottom: 8px">{{ billing.billingAccount.pxcCustomerId }}</div>

                <pc-button
                  variant="secondary"
                  [disabled]="!authorisation.isAdmin(organisation)"
                  size="small"
                  [text]="'USR_SETTINGS.EDIT_BILLING_ACCOUNT' | translate"
                  (btnClick)="billing.showBillingAddressForm$.next(true)"></pc-button>
              </div>

              <div *ngIf="!billing.billingAccount">
                <pc-button
                  [disabled]="!authorisation.isAdmin(organisation)"
                  size="small"
                  [text]="'USR_SETTINGS.CREATE_BILLING_ACCOUNT' | translate"
                  (btnClick)="billing.showBillingAddressForm$.next(true)"></pc-button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="proficloud.currentOrganisation !== organisation">
            <flex-row> {{ 'USER_MANAGEMENT_SERVICE.PLEASE_SWITCH_ORGANIZATION_BILLING' | translate }} </flex-row>
          </ng-container>
          <!-- </flex-col> -->
        </mat-tab>
        <!-- Users tab -->
        <mat-tab label="Users">
          <!-- <flex-col *templateMarker> -->
          <div style="margin-bottom: 40px">
            <table id="members" class="custom-table">
              <tr>
                <th>{{ 'USER_MANAGEMENT_SERVICE.USERS_LIST_NAME' | translate }}</th>
                <th>{{ 'USER_MANAGEMENT_SERVICE.USERS_LIST_EMAIL' | translate }}</th>
                <th>{{ 'USER_MANAGEMENT_SERVICE.USERS_LIST_ROLES' | translate }}</th>
              </tr>
              <tr *ngFor="let member of members | sortBy: 'asc' : 'user.firstName'">
                <td>{{ member.user.firstName }} {{ member.user.lastName }}</td>
                <td style="padding-right: 20px">{{ member.user.email }}</td>
                <td>{{ authorisation.roleLabels[forcedRole(member.userRole)] }}</td>
              </tr>
            </table>
          </div>
          <flex-row>
            <div>
              <pc-button
                *ngIf="authorisation.isAdmin(proficloud.currentOrganisation)"
                variant="secondary"
                size="small"
                [text]="'USER_MANAGEMENT_SERVICE.GO_TO_SERVICE' | translate"
                (btnClick)="openUMS()"></pc-button>
            </div>
          </flex-row>
          <!-- </flex-col> -->
        </mat-tab>
        <!-- </pc-tab-bar> -->
      </mat-tab-group>
    </div>
  </div>
</div>

<pc-overlay *ngIf="showLeaveOrg" [config]="leaveOrgDialog" (closeClick)="showLeaveOrg = false">
  <ng-container body>
    <div style="width: 100%; margin-top: 20px">
      <b>{{ 'USER_MANAGEMENT_SERVICE.LEAVE_ORGANIZATION_DIALOG_1' | translate }}</b>
      <p>{{ 'USER_MANAGEMENT_SERVICE.LEAVE_ORGANIZATION_DIALOG_2' | translate }}</p>
      <ul>
        <li>{{ 'USER_MANAGEMENT_SERVICE.LEAVE_ORGANIZATION_DIALOG_3' | translate }}</li>
        <li>{{ 'USER_MANAGEMENT_SERVICE.LEAVE_ORGANIZATION_DIALOG_4' | translate }}</li>
        <ng-container *ngIf="proficloud.currentOrganisationMembers.length === 1">
          <li>{{ 'USER_MANAGEMENT_SERVICE.LEAVE_ORGANIZATION_DIALOG_5' | translate }}</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>

  <ng-container buttons>
    <pc-button
      id="leave-org"
      variant="deletion"
      size="small"
      [text]="'USER_MANAGEMENT_SERVICE.LEAVE_ORGANIZATION' | translate"
      (btnClick)="leaveOrganization(); $event.stopPropagation()"></pc-button>
  </ng-container>
</pc-overlay>

<pc-overlay *ngIf="showEditOrg" [config]="editOrgDialog" (closeClick)="showEditOrg = false">
  <ng-container body>
    <form [formGroup]="editOrgForm" style="display: block; width: 100%">
      <div style="width: 100%; margin-bottom: 20px">
        <pc-input-textfield
          [control]="editOrgNameControl"
          placeholder="Organization Name"
          variant="fit-width"
          hint="Allowed characters are (a-z A-Z 0-9 - _ . @)"></pc-input-textfield>
      </div>
    </form>
  </ng-container>

  <ng-container buttons>
    <pc-button
      id="rename-org"
      size="small"
      [text]="'USER_MANAGEMENT_SERVICE.RENAME_ORGANIZATION' | translate"
      [disabled]="!editOrgForm.valid"
      (btnClick)="renameOrganization(); $event.stopPropagation()"></pc-button>
  </ng-container>
</pc-overlay>
