import { ConnectionPositionPair } from '@angular/cdk/overlay'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { NotificationService } from '@services/notification.service'
import { SystemNotification } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  @ViewChild('notificationListContainer') notificationListContainer: ElementRef
  notificationPanelWidth: number = 0

  showDeleteConfirmation = false

  currentNotification: SystemNotification

  notificationPanelVisible: boolean = false

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 0,
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ]

  constructor(public notificationService: NotificationService, public proficloud: ProficloudService) {}

  ngOnInit(): void {
    this.notificationService.showNotifications$.subscribe((res) => {
      this.notificationPanelVisible = res
      this.onResize()
    })

    this.notificationService.showDeleteConfirmation$.subscribe((res) => {
      this.showDeleteConfirmation = true
      this.currentNotification = res
    })

    // we need some time here until the notification list is rendered
    /* setTimeout(() => {
      this.onResize()
    }, 1000)*/
  }

  toggleNotificationPanel() {
    this.onResize()
    this.notificationPanelVisible = !this.notificationPanelVisible
  }

  /*
  This method is used to get the width of the global notification list div, in order to set the correct with of the notification panel.
   */
  onResize() {
    this.notificationPanelWidth = this.notificationListContainer.nativeElement.offsetWidth
  }
}
