import { HttpErrorResponse } from '@angular/common/http'
// angular
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { EmmaService } from '@emmaServices/emma.service'
import { environment } from '@environments/environment'
import { TranslateService } from '@ngx-translate/core'
import { AuthorisationService } from '@services/authorisation.service'
import { BillingService } from '@services/billing/billing.service'
import { IamService } from '@services/iam.service'
import {
  AnchorElementNames,
  BookingPackage,
  ExpansionStates,
  Organisation,
  OrganisationTabs,
  PCOverlayConfig,
  ProficloudInputConfig,
  ServiceIds,
  UserFormData,
} from '@services/proficloud.interfaces'
import { UiService } from '@services/ui/ui.service'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { AppService } from 'src/app/app.service'
import { PCStatusOverlayConfig } from 'src/app/modules/shared/components/pc-status-overlay/pc-status-overlay.component'
import { ITab } from 'src/app/modules/shared/components/pc-tab-bar/pc-tab-bar.component'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'
import { sharedAnimations } from 'src/app/modules/shared/shared.animations'
import { traced } from '../../../shared/decorators/trace-decorator'
import { TranslationService } from '../../../shared/services/i18n/translation.service'
// app
import { MatTabGroup } from '@angular/material/tabs'
import { ProficloudService } from '@services/proficloud.service'
import { ClassDoc } from '../../../shared/services/tracing/tracing.interfaces'
import { TracingService } from '../../../shared/services/tracing/tracing.service'

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  animations: [sharedAnimations.heightSlideEnterLeave, sharedAnimations.heightSlide],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  chinaMode = environment.chinaMode

  currentLocation = window.location.href

  emmaPackagesListed$ = new Subject()

  organisations: Organisation[] = []

  activeOrganisation: Organisation

  @ViewChild('emma')
  emmaAnchor: ElementRef

  @ViewChild('impulseAnalytics')
  impulseAnalyticsAnchor: ElementRef

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup
  selectdTabIndex: number = 0

  classDoc: ClassDoc = {
    name: 'UserSettingsComponent',
    location: '/src/app/modules/proficloud/components/user-settings/user-settings.component.ts',
  }

  overlay?: PCOverlayConfig

  dialogs: Record<string, PCOverlayConfig> = {
    // account deletion
    read: {
      title: 'Delete your account',
    },
    confirm: {
      title: 'Confirmation required',
    },
    success: {
      title: 'Success!',
    },
    // edit profile
    editProfile: {
      title: 'Profile data',
    },
    confirmEmailChange: {
      title: 'Confirm email change',
    },
    editProfileSuccess: {
      title: 'Success',
    },
  }

  updateUserFields: ProficloudInputConfig[]

  updateUserForm: UntypedFormGroup

  confirmDeleteControl = new UntypedFormControl('', [Validators.required])

  confirmDeleteForm = new UntypedFormGroup({ email: this.confirmDeleteControl })

  emmaPackages: BookingPackage[]

  // translations
  translationSubscriptions: Subscription[] = []

  timeout: number

  panels: { [key in ServiceIds]: ExpansionStates } = {
    tsd: 'collapsed',
    ia: 'collapsed',
    emma: 'collapsed',
    dmsAddonBasic: 'collapsed',
    'tsd-prologis': 'collapsed',
    chargeRepay: 'collapsed',
  }

  tabs: ITab[] = []

  activeTab = 'organizations'

  initialTab: OrganisationTabs
  organisationsListedSubscription: Subscription

  organisationSwitchedSubscription: Subscription

  // this subject will be called whenever a user menu of the expandable member component opens.
  organisationMenuOpened$ = new Subject()

  loading = true

  constructor(
    public app: AppService,
    public authorization: AuthorisationService,
    public billing: BillingService,
    public cd: ChangeDetectorRef,
    public emma: EmmaService,
    public proficloud: ProficloudService,
    private iam: IamService,
    public route: ActivatedRoute,
    public router: Router,
    public tracing: TracingService,
    public translate: TranslateService,
    public translationService: TranslationService,
    public ui: UiService,
    private statusOverlay: PcStatusOverlayService
  ) {}

  ngOnInit() {
    //this.initializeTabs(this.route.snapshot.queryParams['Tab'] || 'Organizations')
    this.route.queryParams.subscribe((params) => {
      const tabName = params['tab']

      this.selectdTabIndex = this.getTabIndex(tabName)
    })
    this.route.fragment.subscribe((f) => {
      const element = document.querySelector('#' + f)
      if (element) {
        element.scrollIntoView()
      }
    })

    this.organisationsListedSubscription = this.proficloud.organisationsListed$.subscribe((x) => {
      this.organisations = this.proficloud.organisations.filter((o) => o.organizationId !== this.proficloud.currentOrganisation.organizationId)
      this.activeOrganisation = this.proficloud.currentOrganisation
      this.activeOrganisation.expansionState = 'expanded'
      //this.initializeTabs(this.route.snapshot.queryParams['Tab'] || 'Organizations')
    })

    this.organisationSwitchedSubscription = this.proficloud.organisationSwitched$.subscribe((switchedOrga) => {
      this.organisations = this.proficloud.organisations.filter((o) => o.organizationId !== this.proficloud.currentOrganisation.organizationId)
      // collapse former but still active organization
      this.activeOrganisation.expansionState = 'collapsed'
      this.activeOrganisation = switchedOrga
      this.activeOrganisation.expansionState = 'expanded'

      this.statusOverlay.resetStatus()

      //this.initializeTabs(this.route.snapshot.queryParams['Tab'] || 'Organizations')
      this.route.queryParams.subscribe((params) => {
        const tabName = params['tab']
        this.selectdTabIndex = this.getTabIndex(tabName)
      })
    })

    this.performInit()
  }

  ngOnDestroy() {
    clearTimeout(this.timeout)
    // Translation: Unsubscribe from each subscription
    this.translationSubscriptions.map((sub) => {
      sub.unsubscribe()
    })

    this.organisationSwitchedSubscription?.unsubscribe()
    this.organisationsListedSubscription?.unsubscribe()
  }
  private getTabIndex(tabName: string | undefined): number {
    switch (tabName) {
      case 'Organizations':
        return 0
      case 'Certificates':
        return 1
      case 'Account Settings':
        return 2
      default:
        return 0
    }
  }
  private performInit() {
    // This is clearly a bit of a mess, but it's honestly the only way I could figure out to get it reliably working for emma in all cases
    combineLatest([this.emmaPackagesListed$, this.router.events]).subscribe(([t, s]) => {
      if (s instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url)
        if (tree.fragment) {
          setTimeout(() => {
            // Note: We now first have to go to the subscriptions tab of the active organisation
            this.initialTab = tree.fragment as OrganisationTabs

            const element: ElementRef = this[(tree.fragment + 'Anchor') as AnchorElementNames]
            if (element) {
              // Note: In the case of it not being emma (impulseAnalytics) this does not work on page reload because it needs to wait for something service specific, so this should not be considered finished for all cases, only emma.
              element.nativeElement.scrollIntoView()
            } else {
              this.billing.emmaSubscriptionsListed$.subscribe((r) => {
                this.timeout = window.setTimeout(() => {
                  const e: ElementRef = this[(tree.fragment + 'Anchor') as AnchorElementNames]
                  if (e) {
                    e.nativeElement.scrollIntoView()
                  }
                })
              })
            }
          })
        }
      }
    })

    this.tracing.registerInstance(this)

    // Create dropdown list of possible languages
    const selectLanguageValues: { key: string; value: string; visible: () => true | false }[] = []
    for (const language of this.translationService.getSupportedLanguages()) {
      selectLanguageValues.push({ key: language.isoCode, value: language.languageName, visible: () => true })
    }

    // load user details (duplicate with proficloud authenticate, refactor )
    this.iam.getIamUser().subscribe((res) => {
      // only after the details are known, can be build the form
      this.updateUserFields = [
        {
          type: 'text',
          placeholder: 'First name',
          control: new UntypedFormControl(this.proficloud.keycloakData.userDetails?.data.firstName || '', [Validators.required]),
          key: 'firstName',
        },
        {
          type: 'text',
          placeholder: 'Last name',
          control: new UntypedFormControl(this.proficloud.keycloakData.userDetails?.data.lastName || '', [Validators.required]),
          key: 'lastName',
        },
        {
          type: 'text',
          placeholder: 'Email',
          control: new UntypedFormControl(this.proficloud.keycloakData.userDetails?.data.email, [Validators.required, Validators.email]),
          key: 'email',
        },
      ]

      if (environment.chinaMode) {
        const locale = this.proficloud.keycloakData.userDetails?.data.attributes?.locale
        if (!locale?.length) {
          console.warn('Locale not found')
          return
        }

        this.updateUserFields.push({
          type: 'select',
          placeholder: 'Language',
          value: locale[0],
          selectValues: selectLanguageValues,
          control: new UntypedFormControl(locale[0], [Validators.required]),
          key: 'locale',
        })
      }

      this.updateUserForm = new UntypedFormGroup(this.ui.formFieldsToObject(this.updateUserFields))

      // Translation
      this.translationSubscriptions.push(
        this.translate.stream('USR_SETTINGS.FIRST_NAME').subscribe((translation) => {
          const field = this.updateUserFields.find((form) => form.key === 'firstName')
          if (field) {
            field.placeholder = translation
          }
        })
      )

      this.translationSubscriptions.push(
        this.translate.stream('USR_SETTINGS.LAST_NAME').subscribe((translation) => {
          const field = this.updateUserFields.find((form) => form.key === 'lastName')
          if (field) {
            field.placeholder = translation
          }
        })
      )

      this.translationSubscriptions.push(
        this.translate.stream('USR_SETTINGS.EMAIL').subscribe((translation) => {
          const field = this.updateUserFields.find((form) => form.key === 'email')
          if (field) {
            field.placeholder = translation
          }
        })
      )

      if (environment.chinaMode) {
        this.translationSubscriptions.push(
          this.translate.stream('USR_SETTINGS.LANGUAGE').subscribe((translation) => {
            const field = this.updateUserFields.find((form) => form.key === 'locale')
            if (field) {
              field.placeholder = translation
            }
          })
        )
      }
    })

    // Load packages details
    this.billing.listPackagesPerService('emma').subscribe((res) => {
      this.billing.emmaPackages = res
      this.emmaPackagesListed$.next(true)
    })

    if (this.proficloud.organisations) {
      this.organisations = this.proficloud.organisations.filter((o) => o.organizationId !== this.proficloud.currentOrganisation.organizationId)
      this.activeOrganisation = this.proficloud.currentOrganisation
      this.activeOrganisation.expansionState = 'expanded'
    }
  }

  @traced({
    why: 'The user wants to delete their account',
  })
  deleteAccount() {
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.deleteUserBusy)

    if (this.confirmDeleteForm.value.email === this.proficloud.keycloakData.userDetails?.data.email) {
      this.iam.deleteOwnUser().subscribe({
        next: (usr_del_res) => {
          this.statusOverlay.resetStatus()
          this.overlay = this.dialogs.success
          setTimeout(() => {
            this.proficloud.performLogout()
          }, 10000)
        },
        error: (error: HttpErrorResponse) => {
          const errData = error?.error?.error
          if (errData) {
            switch (errData.id) {
              case 'iam.ORGANIZATION_USER_IS_LAST_ADMIN_ERROR':
                const laOrgs: string[] = errData.details.Organizations.map((o: any) => o.name)
                this.statusOverlay.showStatus(this.createDynamicLastAdminErrorContent(laOrgs))
                break
              default:
                this.statusOverlay.showStatus(this.proficloud.statusOverlays.deleteUserError)
            }
          }
        },
      })
    } else {
      this.statusOverlay.showStatus(this.proficloud.statusOverlays.deleteUserWrongEmailError)
    }
  }

  updateUserDetails(confirmed = false) {
    const emailChanged = this.updateUserForm.value.email !== this.proficloud.keycloakData.userDetails?.data.email

    const userFormData: UserFormData = {
      firstName: this.updateUserForm.value.firstName,
      lastName: this.updateUserForm.value.lastName,
    }

    // Copy attributes and check for locale changes
    // This is required to keep all other keycloak attributes in place since PUT will overwrite everything.
    const newAttributes = this.proficloud.keycloakData.userDetails?.data.attributes
    if (!newAttributes) {
      console.warn('User attributes not found')
      return
    }

    delete newAttributes.locale
    if (this.updateUserForm.value.locale && this.updateUserForm.value.locale !== this.proficloud.keycloakData.userDetails?.data.attributes?.locale) {
      // set new locale
      this.translate.use(this.updateUserForm.value.locale)
      newAttributes.locale = [this.updateUserForm.value.locale]
      userFormData['locale'] = [this.updateUserForm.value.locale]
    }

    // only send when the email changed
    if (emailChanged && !confirmed) {
      // Show confirmation dialog
      // Timeout in order to avoud dialog appearance bug
      setTimeout(() => {
        this.overlay = this.dialogs.confirmEmailChange
      }, 300)
      return
    } else if (emailChanged && confirmed) {
      // Include email in post data
      userFormData.email = this.updateUserForm.value.email
    }

    this.iam.updateUserDetails(userFormData).subscribe({
      next: (res) => {
        this.iam.updateUserAttributes(newAttributes).subscribe({
          next: (updateAttrRes) => {
            this.iam.refreshUserDetails()
          },
          error: (err: HttpErrorResponse) => {
            console.log(err)
            this.overlay = undefined
            this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateUserError)
          },
        })

        if (emailChanged) {
          this.statusOverlay.showStatus(this.proficloud.statusOverlays.emailChangedSuccess)
          setTimeout(() => {
            this.proficloud.performLogout()
          }, 6000)
        } else {
          this.overlay = this.dialogs.editProfileSuccess
        }
      },
      error: (err: HttpErrorResponse) => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.updateUserError)
        this.proficloud.logoutOnUnauthorised(err)
        console.log(err)
      },
    })
  }

  createDynamicLastAdminErrorContent(lastAdminOrganizations: any[]): PCStatusOverlayConfig {
    return {
      type: 'error',
      message:
        'It was not possible to delete your account, since you are the last administrator of at least one organization. In all organizations where you are not alone, you must promote another member to administrator.',
      closeable: true,
      extraInfo: `The following organizations are affected: ${lastAdminOrganizations.map((o) => o)}`,
    }
  }

  openContactForm() {
    window.open('https://proficloud.io/contact/', '_blank')
    this.overlay = undefined
  }

  /*
  Hide certain tabs/settings depending on roles/permissions
   */
  // initializeTabs(Tab: string) {
  //   this.tabs = [
  //     {
  //       name: 'Organizations',
  //       id: 'organizations',
  //       selected: false,
  //     },
  //     {
  //       name: 'Certificates',
  //       id: 'certificates',
  //       selected: false,
  //     },
  //     {
  //       name: 'Account Settings',
  //       id: 'accountSettings',
  //       selected: false,
  //     },
  //   ]
  //   const setTab = this.tabs.find((tab) => tab.name === Tab)
  //   if (setTab) {
  //     setTab.selected = true
  //   }
  // }

  protected readonly console = console
}
