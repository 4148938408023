<pc-overlay [config]="{ title: 'Select Subscription' }" (closeClick)="billing.showSelectSubscriptionModal$.next(false)">
  <ng-container body>
    <p>{{ 'DEVICE.SELECT_SUBSCRIPTION_ASSIGN' | translate }}</p>
    <div
      class="subscriptionOption"
      *ngFor="let subscription of availableSubscriptions"
      [ngClass]="subscriptionAssignable(subscription) ? '' : 'disabledSubscriptionOption'">
      <input
        class="subscriptionRadioButton"
        type="radio"
        [value]="subscription.referenceCode"
        name="selectSubcriptionRadio"
        (click)="selectedSubscription = subscription"
        [disabled]="!subscriptionAssignable(subscription)" />
      <flex-col>
        <div class="subscriptionHeadline">
          <span class="subscriptionName">
            {{ subscription.referenceCode }}
            ({{ subscriptionInfo(subscription).usedSlots }}/{{ subscriptionInfo(subscription).totalSlots }} {{ 'DEVICE.SUBSCRIPTION_ASSIGNED' | translate }})
          </span>
          <span *ngIf="subscription === selectedSubscription">
            → ({{ subscriptionInfo(subscription).usedSlots + 1 }}/{{ subscriptionInfo(subscription).totalSlots }})
          </span>
        </div>
        <div class="subscriptionDates">{{ 'DEVICE.SUBSCRIPTION_BOOKED' | translate }} {{ subscription.startDate | date }}</div>
      </flex-col>
    </div>
  </ng-container>

  <ng-container buttons>
    <flex-row-between-center class="subscriptionButtons">
      <pc-button
        variant="secondary"
        size="small"
        [text]="'DEVICE.SKIP_STEP' | translate"
        (btnClick)="billing.showSelectSubscriptionModal$.next(false)"></pc-button>
      <pc-button [disabled]="!selectedSubscription" size="small" [text]="'DEVICE.SAVE_ASSIGNMENT' | translate" (click)="updateSubscription()"></pc-button>
    </flex-row-between-center>
  </ng-container>
</pc-overlay>
