<pc-overlay [config]="{ title: 'Assign Metrics' }" (closeClick)="billing.showAssignMetricsModal$.next(false)">
  <ng-container body>
    <div *ngIf="tsdDevices().length === 0" style="width: 100%">
      <div style="font-size: 20px; margin: 20px 0">{{ 'STORE.PLEASE_ADD_COMPATIBLE_DEVICES' | translate }}</div>
      <div style="margin-top: 20px">
        <pc-button
          [text]="'STORE.OPEN_FAQ' | translate"
          (btnClick)="ui.openURL('https://proficloud.io/knowledge-hub/faqs/general/which-device-can-use-which-smart-service/')"></pc-button>
      </div>
    </div>

    <!-- used metric slots info -->
    <div *ngIf="billing.getTsdPackageById(billing.tsdSubscription.bookedPackageId) as package" style="width: 100%">
      <div
        style="margin-bottom: 15px"
        [innerHTML]="
          'USR_SETTINGS.TSD_GENERAL_SLOT_INFORMATION'
            | translate
              : {
                  param1: billing.tsdToalMetricsUsed,
                  param2: countTempAssignedMetrics(),
                  param3: package.maxNumberOfMetrics
                }
        "></div>
    </div>

    <div *ngIf="tsdDevices().length > 0" style="width: 100%">
      <!-- filter -->
      <div>
        <!-- new: metric filter -->
        <div class="filter-container">
          <app-icon class="search-icon" [color]="'gray'" name="search"> </app-icon>
          <input class="filter-input" (input)="metricFilterInput$.next($event.target.value)" placeholder="Metric Search" />
        </div>
      </div>
      <div style="width: 100%; max-height: 60vh; overflow-y: scroll">
        <ng-container *ngFor="let device of filteredTsdDevices$ | async">
          <table [style.display]="showDevice(device.endpointId) ? 'table' : 'none'" style="width: 100%; border-collapse: collapse">
            <tr style="background: var(--alabaster)">
              <td style="border: 1px solid var(--black-haze); width: 55px; padding: 10px 0; border-right: none">
                <flex-col-start-center style="">
                  <mat-checkbox
                    [indeterminate]="someMetricsAssigned(device)"
                    [checked]="allMetricsAssigned(device)"
                    (change)="endpointToggle($event.checked, device)"></mat-checkbox>
                </flex-col-start-center>
              </td>
              <td style="border: 1px solid var(--black-haze); padding: 15px; cursor: pointer; border-left: none">
                <flex-col>
                  <div style="font-size: 20px; padding: 10px 0" class="semibold" [title]="device.metadata.deviceName">
                    {{ device.metadata.deviceName | truncate: [20] }}
                  </div>
                  <div style="color: #666">{{ device.metadata.uuid }}</div>
                </flex-col>
              </td>
            </tr>
          </table>

          <div [@heightSlide]="expansionState[device.endpointId]" style="width: 100%">
            <table [style.display]="showDevice(device.endpointId) ? 'table' : 'none'" style="width: 100%; margin-bottom: 20px; border-collapse: collapse">
              <div *ngIf="deviceAvailableAndFilteredMetrics(device).length === 0" style="padding: 20px; font-style: italic; color: #555">
                no matching metrics found
              </div>

              <ng-container *ngFor="let metric of deviceAvailableAndFilteredMetrics(device); let even = even">
                <ng-container>
                  <tr [style.background]="even ? 'var(--alabaster)' : 'white'">
                    <td style="border: 1px solid var(--black-haze); width: 55px; padding: 10px 0; border-right: none">
                      <flex-row-center>
                        <mat-checkbox
                          [checked]="tempAssignedMetrics[device.endpointId]?.includes(metric)"
                          (change)="toggleMetric($event.checked, device.endpointId, metric)"></mat-checkbox>
                      </flex-row-center>
                    </td>
                    <td style="border: 1px solid var(--black-haze); padding: 8px 15px; cursor: default; border-left: none">
                      {{ metric }}
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </table>
          </div>
        </ng-container>
      </div>

      <flex-row-end style="margin-top: 20px">
        <pc-button id="edit-save" [text]="'STORE.ASSIGN_METRICS' | translate" (btnClick)="assignMetricsClick()"></pc-button>
      </flex-row-end>
    </div>
  </ng-container>
</pc-overlay>
