<pc-overlay [config]="{ title: 'Assign Devices' }" (closeClick)="billing.showAssignDevicesModal$.next(false)">
  <ng-container body>
    <div *ngIf="config?.candidateDevices?.length === 0" style="width: 100%">
      <div style="font-size: 20px; margin: 20px 0">{{ 'STORE.PLEASE_ADD_COMPATIBLE_DEVICES' | translate }}</div>
      <div style="margin-top: 20px">
        <pc-button
          [text]="'STORE.OPEN_FAQ' | translate"
          (btnClick)="ui.openURL('https://proficloud.io/knowledge-hub/faqs/general/which-device-can-use-which-smart-service/')"></pc-button>
      </div>
    </div>

    <div
      style="padding-bottom: 10px; width: 100%"
      [innerHTML]="
        'USR_SETTINGS.GENERAL_SLOT_INFORMATION' | translate: { param1: devicesToAdd + config.devicesUsed?.length, param2: config.subscription.quantity }
      "></div>
    <div style="width: 100%">
      <!-- Filter -->
      <div class="filter-container">
        <app-icon class="search-icon" [color]="'gray'" name="search"> </app-icon>
        <input class="filter-input" (input)="filterInput$.next($event.target.value)" placeholder="Search" />
      </div>
    </div>

    <div style="width: 100%" *ngIf="config?.candidateDevices?.length > 0">
      <table style="width: 100%; border-collapse: collapse">
        <tr style="background: var(--alabaster)">
          <td style="border: 1px solid var(--black-haze); padding: 15px">{{ 'STORE.ASSIGNED' | translate }}</td>
          <td style="border: 1px solid var(--black-haze); padding: 15px">{{ 'STORE.DEVICE' | translate }}</td>
        </tr>

        <ng-container *ngFor="let device of filteredDevices$ | async">
          <tr *ngIf="deviceAssignedState(device, config.subscription) as added">
            <td style="border: 1px solid var(--black-haze); padding: 8px 15px">
              <flex-row-center>
                <mat-checkbox
                  class="checkbox"
                  [checked]="added === 'here' || added === 'elsewhere'"
                  [disabled]="added === 'elsewhere'"
                  (change)="onCheckChange($event.checked, device.endpointId)"></mat-checkbox>
              </flex-row-center>
            </td>
            <td style="border: 1px solid var(--black-haze); padding: 8px 15px" [title]="device.metadata.deviceName">
              {{ device.metadata.deviceName | truncate: [30] }}
            </td>
          </tr>
        </ng-container>
      </table>

      <flex-row-end style="margin-top: 20px">
        <pc-button [text]="'STORE.ASSIGN_DEVICES' | translate" (btnClick)="billing.showAssignDevicesModal$.next(false); assignDevices()"></pc-button>
      </flex-row-end>
    </div>
  </ng-container>
</pc-overlay>
