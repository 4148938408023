import { Component, OnInit } from '@angular/core'
import { MultiStatusOverlayContent } from '../../services/proficloud.interfaces'
import { ProficloudService } from '../../services/proficloud.service'

@Component({
  selector: 'app-multi-status-overlay',
  templateUrl: './multi-status-overlay.component.html',
  styleUrls: ['./multi-status-overlay.component.scss', '../../../shared/styles/shared-styles.scss'],
})
export class MultiStatusOverlayComponent implements OnInit {
  auto_close_time: number = 2000

  constructor(public proficloud: ProficloudService) {}

  ngOnInit(): void {
    // auto_close mechanism
    this.proficloud.multiStatusOverlay$.subscribe({
      next: (status) => {
        if (status && status.auto_close && status.status.filter((entry) => entry.icon == 'spinner').length == 0) {
          setTimeout(() => this.proficloud.multiStatusOverlay$.next(false), this.auto_close_time)
        }
      },
    })
  }

  asMultiStatusOverlayContent(content: MultiStatusOverlayContent): MultiStatusOverlayContent {
    return content
  }
}
