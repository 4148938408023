import { HttpErrorResponse } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { EmmaService } from '@emmaServices/emma.service'
import { BillingService } from '@services/billing/billing.service'
import { EmmaAvailableDevice, EmmaDeviceResponse, EmmaSubscription } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { PcStatusOverlayService } from 'src/app/modules/shared/services/pc-status-overlay/pc-status-overlay.service'

@Component({
  selector: 'app-emma-assign-devices-modal',
  templateUrl: './emma-assign-devices-modal.component.html',
  styleUrls: ['./emma-assign-devices-modal.component.scss'],
})
export class EmmaAssignDevicesModalComponent implements OnInit {
  confirming = false

  @Input() config: {
    subscription: EmmaSubscription
    candidateDevices: EmmaAvailableDevice[]
  }

  subscriptionEndpointIds: string[]

  endpointsToAdd = 0

  constructor(
    public billing: BillingService,
    public emma: EmmaService,
    public proficloud: ProficloudService,
    public router: Router,
    private statusOverlay: PcStatusOverlayService,
    public ui: UiService
  ) {}

  ngOnInit(): void {
    // create an array of used id's to work with
    const ids = this.config.subscription.assignedDevices || []
    this.subscriptionEndpointIds = JSON.parse(JSON.stringify(ids))
    this.emma.searchInput$.subscribe((query) => {
      this.config.candidateDevices.forEach((meter) => {
        const meterText = JSON.stringify(meter)
        const spaces = /\s+/g
        const trimmed = query.trim().split(spaces).join(' ')

        meter.filteredIn = meterText.toLowerCase().includes(trimmed.toLowerCase())
        meter.filteredOut = !meter.filteredIn
      })
    })
  }

  // we want to know the state of each candidate device. added (here, elsewhere, nowhere)
  deviceAssignedState(device: EmmaAvailableDevice, subscription: EmmaSubscription): 'here' | 'elsewhere' | 'nowhere' {
    // check if it's part of this subscription
    if (device.assignmentState === 'not_assigned') {
      return 'nowhere'
    }

    if (device.assignmentState === 'assigned') {
      if (device.subscription_id === subscription.id) {
        return 'here'
      } else {
        return 'elsewhere'
      }
    }

    if ((subscription.assignedDevices || []).includes(device.device_id)) {
      return 'here'
    }

    // get all other subscriptions
    const allotherSubscriptionDevices = this.billing.emmaSubscriptions.filter((sub) => sub.id !== subscription.id).map((s) => s.assignedDevices)
    const flatDevices = ([] as string[]).concat(...allotherSubscriptionDevices)
    if (flatDevices.includes(device.device_id)) {
      return 'elsewhere'
    }

    return 'nowhere'
  }

  assignDevices() {
    const devices = this.subscriptionEndpointIds.filter((id) => !(this.config.subscription.assignedDevices || []).includes(id))

    this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesBusy)

    this.billing.setEmmaAssignedDevices(this.config.subscription.id, devices, this.config.subscription.bookedPackageId === 'EMMAXXL').subscribe({
      next: (r) => {
        this.billing.fetchSubscriptions()

        this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesSuccess)

        setTimeout(() => this.statusOverlay.resetStatus(), 2000)

        this.endpointsToAdd = 0
      },
      error: (error: HttpErrorResponse) => {
        this.statusOverlay.showStatus(this.proficloud.statusOverlays.assignDevicesError)
        console.log('assign emma devices error', error)
      },
    })
  }

  onCheckChange(checked: boolean, endpointId: string) {
    if (checked) {
      this.subscriptionEndpointIds.push(endpointId)
      this.endpointsToAdd++
    } else {
      this.subscriptionEndpointIds = this.subscriptionEndpointIds.filter((id) => id !== endpointId)
      this.endpointsToAdd--
    }
  }

  deviceAsEmmaDevice(device: EmmaDeviceResponse): EmmaDeviceResponse {
    return device
  }

  deviceAsEmmaAvailableDevice(device: EmmaAvailableDevice): EmmaAvailableDevice {
    return device
  }
}
