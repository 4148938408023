import { animate, state, style, transition, trigger } from '@angular/animations'

export const minimizingAnimation = trigger('minimizeNavigation', [
  state(
    'minimized',
    style({
      width: 56,
    })
  ),
  state(
    'maximized',
    style({
      width: 250,
    })
  ),
  transition('minimized <=> maximized', [animate('0.3s ease')]),
])
