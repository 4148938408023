import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { EnvironmentInterface } from '@environments/environment.interface'

@Injectable({
  providedIn: 'root',
})
export class WhitelabelService {
  private env: EnvironmentInterface = environment

  // A background image for the top bar
  topBarBackgroundImage = ''
}
