import { Pipe, PipeTransform } from '@angular/core'
import { ProficloudService } from '@services/proficloud.service'
import { AppService } from 'src/app/app.service'
import { NavItem } from '../components/navigation/navigation.model'

@Pipe({
  name: 'navItemChildVisible',
})
export class NavItemChildVisiblePipe implements PipeTransform {
  constructor(private proficloud: ProficloudService, private app: AppService) {}

  transform(child: NavItem, ...args: unknown[]): unknown {
    return this.proficloud.navItemChildVisible(child)
  }
}
