import { Pipe, PipeTransform } from '@angular/core'
import { ProficloudService } from '@services/proficloud.service'
import { NavItem } from '../components/navigation/navigation.model'

@Pipe({
  name: 'navItemHasVisibleChild',
})
export class NavItemHasVisibleChildPipe implements PipeTransform {
  constructor(private proficloud: ProficloudService) {}

  transform(navItem: NavItem, ...args: unknown[]): unknown {
    if (!navItem.children) {
      return false
    }

    for (let child of navItem.children) {
      if (this.proficloud.navItemChildVisible(child)) {
        return true
      }
      return false
    }
  }
}
