<flex-col-center-center style="height: 100%">
  <div>
    <flex-row-center class="notFoundCode">404</flex-row-center>
    <flex-row-center class="notFoundWording">{{ 'APP.NOT_FOUND_WORDING' | translate }}</flex-row-center>

    <flex-row-center class="notFoundMessage">{{ 'APP.NOT_FOUND_MESSAGE' | translate }}</flex-row-center>

    <flex-row-center class="notFoundLinks">
      <a (click)="location.back()" [href]="" class="notFoundLink">{{ 'APP.NOT_FOUND_LINK_BACK' | translate }}</a>
      <a routerLink="/" class="notFoundLink">{{ 'APP.NOT_FOUND_LINK_HOME' | translate }}</a>
      <a class="notFoundLink" href="https://proficloud.io">{{ 'APP.NOT_FOUND_LINK_HELP' | translate }}</a>
    </flex-row-center>
  </div>
</flex-col-center-center>
