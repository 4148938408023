<div id="status-overlay" class="shell-overlay-screen shell-statusOverlay" *ngIf="proficloud.multiStatusOverlay$ | async as status">
  <div class="styles-overlay-content" css="align-items: left;">
    <!-- close icon -->
    <app-icon
      *ngIf="asMultiStatusOverlayContent(status).closeable"
      name="cross"
      color="#ccc"
      class="styles-deviceManagement-addDevice-close"
      (click)="proficloud.multiStatusOverlay$.next(false)"></app-icon>

    <flex-col>
      <flex-row *ngFor="let statusEntry of asMultiStatusOverlayContent(status).status">
        <div class="icon">
          <!-- success icon (animated) -->
          <svg
            *ngIf="statusEntry.icon === 'success'"
            version="1.1"
            style="height: 50px; width: 50px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
            <polyline
              class="path check"
              fill="none"
              stroke="#73AF55"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 " />
          </svg>

          <!-- error icon (animated) -->
          <svg
            *ngIf="statusEntry.icon === 'error'"
            version="1.1"
            style="height: 50px; width: 50px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#c53928" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
            <line
              class="path line"
              fill="none"
              stroke="#c53928"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              x1="34.4"
              y1="37.9"
              x2="95.8"
              y2="92.3" />
            <line
              class="path line"
              fill="none"
              stroke="#c53928"
              stroke-width="6"
              stroke-linecap="round"
              stroke-miterlimit="10"
              x1="95.8"
              y1="38"
              x2="34.4"
              y2="92.2" />
          </svg>

          <!-- spinner -->
          <div *ngIf="statusEntry.icon === 'spinner'" class="lds-dual-ring" style="height: 50px; width: 50px"></div>
        </div>
        <!-- message -->
        <div [id]="'status-overlay-message-' + statusEntry.icon" class="message" [innerHTML]="statusEntry.message"></div>
      </flex-row>
    </flex-col>
  </div>
</div>
